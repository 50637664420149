import * as reducer from "./reducers";
import * as sync from "./sync";
import uuidv4 from "uuid/v4";

export const TODO_LIST_PREFIX = "TODO: ";
export const formatSummary = (summary) =>
  summary.substring(TODO_LIST_PREFIX.length);
export const labelSummary = (summary) => TODO_LIST_PREFIX + summary;

/**
 * Make a UUID for use with the Google Calendar API.
 *
 * This is formed from a common UUID by removing the dashes, as
 * Calendar only accepts the characters a-v0-9.
 */
export const uuid = () => {
  return uuidv4().replace(/-/g, "");
};

// Returns the error messages from the list of errors in the 'err' object, or
// undefined if a message can't be found.
function getErrorMessages(err) {
  var message;
  if (err.message) {
    message = err.message;
  } else if (
    err.result &&
    err.result.error &&
    err.result.error.errors &&
    Array.isArray(err.result.errors) &&
    err.result.error.errors.length > 0
  ) {
    message = err.result.error.errors.reduce(
      (acc, val) => (acc += (acc.length > 0 ? ". " : "") + val.message),
      ""
    );
  }
  return message;
}

function encodeDescriptionData(anchorId, creator) {
  return JSON.stringify({ anchor_id: anchorId, creator: creator });
}

function decodeDescriptionData(description) {
  try {
    // if description is the empty string, JSON.parse will fail,
    // so use && to test it first, and return an empty object if
    // there is no data in the calendar description
    return (description && JSON.parse(description)) || {};
  } catch (err) {
    return {};
  }
}

export function createList(action) {
  const listWithoutId = {
    summary: action.name,
    description: encodeDescriptionData(action.anchor_id, action.creator),
  };
  return window.gapi.client.calendar.calendars
    .insert({ resource: listWithoutId })
    .then((response) => {
      reducer.store.dispatch(
        reducer.createListAction(action.id, response.result.id)
      );
    })
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      reducer.store.dispatch(
        reducer.createListErrorAction(action.id, JSON.stringify(message))
      );
      throw err;
    });
}

export function deleteList(action) {
  return window.gapi.client.calendar.calendars
    .delete({ calendarId: action.id })
    .then((response) =>
      reducer.store.dispatch(reducer.deleteListAction(action.id, action.shared))
    )
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      reducer.store.dispatch(
        reducer.deleteListErrorAction(action.id, JSON.stringify(message))
      );
      throw err;
    });
}

function dateToCalString(d) {
  const str = d.toISOString();
  return str.substring(0, str.indexOf("T"));
}

function createTodoEventObject(todoId, summary, prevId, nextId, timestamp) {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const timestamps = reducer.makeTodoTimestampsFor(timestamp);

  const calendarEvent = {
    summary: summary,
    start: { date: dateToCalString(today) },
    end: { date: dateToCalString(tomorrow) },
    id: todoId,
    extendedProperties: {
      shared: {
        done: false,
        prev: prevId,
        next: nextId,
        timestamps: JSON.stringify(timestamps),
      },
    },
  };
  return calendarEvent;
}

export function createAnchor(action) {
  const listId = action.list_id;
  const anchorId = action.id;
  var anchorEvent = createTodoEventObject(
    anchorId,
    reducer.ANCHOR,
    anchorId,
    anchorId,
    action.timestamp
  );

  return window.gapi.client.calendar.events
    .insert({ calendarId: listId, resource: anchorEvent })
    .then((response) => {
      reducer.store.dispatch(reducer.createAnchorAction(anchorId, listId));
    })
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      reducer.store.dispatch(
        reducer.createAnchorErrorAction(
          anchorId,
          listId,
          JSON.stringify(message)
        )
      );
      throw err;
    });
}

export function healAnchor(action) {
  const listId = action.list_id;
  const anchorId = action.anchor_id;
  const description = {
    description: encodeDescriptionData(anchorId, action.creator),
  };

  return window.gapi.client.calendar.calendars
    .patch({ calendarId: listId, resource: description })
    .then((response) => {
      // console.log("HEAL ANCHOR: rewrote server side description");
    })
    .catch((err) => {
      // console.log("HEAL ANCHOR FAILED");
    });
}

function createTodoItem(todoId, listId, summary, timestamp) {
  const getCalendarAPI = window.gapi.client.calendar.calendarList.get;
  const getEventAPI = window.gapi.client.calendar.events.get;
  const patchEventAPI = window.gapi.client.calendar.events.patch;
  let prevId;
  let nextId;
  let prevEvent;

  return getCalendarAPI({ calendarId: listId })
    .then((response) => {
      prevId = decodeDescriptionData(response.result.description).anchor_id;
      if (!prevId) {
        const state = reducer.getUserState(reducer.store.getState());
        const anchor = state[listId].anchor_id;
        if (!anchor) {
          console.log(
            "ERROR: Anchor tag missing on list description AND in local db?"
          );
          sync.runSyncAction(
            reducer.syncTodosPendingAction(listId, [], new Date())
          );
          // This throw will fail the current action, but because we just did an out of
          // order network action, when we retry we shouldn't hit it again.
          throw new Error(
            "ERROR: Anchor tag missing on list description AND in local db?"
          );
        }
        reducer.store.dispatch({
          type: "SERVER_FIXUP_ANCHOR",
          list_id: listId,
          anchor_id: anchor,
          creator: state[listId].creator,
          offline: true,
        });
        prevId = anchor;
      }
      return getEventAPI({ calendarId: listId, eventId: prevId });
    })
    .then((response) => {
      prevEvent = response.result;
      nextId = prevEvent.extendedProperties.shared.next;
      return getEventAPI({ calendarId: listId, eventId: nextId });
    })
    .then((response) => {
      const nextEvent = response.result;
      const calendarEvent = createTodoEventObject(
        todoId,
        summary,
        prevId,
        nextId,
        timestamp
      );
      const createEvent = window.gapi.client.calendar.events.insert({
        calendarId: listId,
        resource: calendarEvent,
      });
      const prevPatchTimestamps = JSON.stringify(
        reducer.mergeTodoTimestamps(prevEvent, { next: timestamp })
      );
      const prevPatch = {
        etag: prevEvent.etag,
        extendedProperties: {
          shared: { next: todoId, timestamps: prevPatchTimestamps },
        },
      };
      const nextPatchTimestamps = JSON.stringify(
        reducer.mergeTodoTimestamps(nextEvent, { prev: timestamp })
      );
      const nextPatch = {
        etag: nextEvent.etag,
        extendedProperties: {
          shared: { prev: todoId, timestamps: nextPatchTimestamps },
        },
      };
      const patchPrev = patchEventAPI({
        calendarId: listId,
        eventId: prevId,
        resource: prevPatch,
      });
      const patchNext = patchEventAPI({
        calendarId: listId,
        eventId: nextId,
        resource: nextPatch,
      });

      const batch = window.gapi.client.newBatch();
      batch.add(createEvent);
      batch.add(patchPrev);
      batch.add(patchNext);
      return batch;
    });
}

export function createTodo(action) {
  const listId = action.list_id;
  const todoId = action.id;

  return createTodoItem(todoId, listId, action.description, action.timestamp)
    .then((response) => {
      reducer.store.dispatch(reducer.createTodoAction(todoId, listId));
    })
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      reducer.store.dispatch(
        reducer.createTodoErrorAction(todoId, listId, JSON.stringify(message))
      );
      throw err;
    });
}

function parseTimestamps(event) {
  const tsJson = event.extendedProperties.shared.timestamps;
  try {
    const timestamps = (tsJson && JSON.parse(tsJson)) || {};
    return timestamps;
  } catch (err) {
    return { error: err.message };
  }
}

export function editTodoFields(action) {
  const getEventAPI = window.gapi.client.calendar.events.get;
  const patchEventAPI = window.gapi.client.calendar.events.patch;
  const listId = action.list_id;
  const todoId = action.id;

  return getEventAPI({ calendarId: listId, eventId: todoId })
    .then((response) => {
      const event = response.result;
      const eventPatch = {
        etag: event.etag,
        extendedProperties: { shared: {} },
      };
      let timestamps = parseTimestamps(event);
      let serverHasUpdate = false;
      action.edits.forEach((edit) => {
        if (
          !timestamps[edit.field] ||
          action.timestamp > timestamps[edit.field]
        ) {
          if (edit.field === "description") {
            eventPatch.summary = edit.value;
          } else if (edit.field === "dueDate" && edit.value) {
            eventPatch.start = { date: edit.value };
            eventPatch.end = { date: edit.value };
          } else if (edit.field === "dueDate") {
            const now = new Date();
            const today = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );
            const tomorrow = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() + 1
            );
            eventPatch.start = { date: dateToCalString(today) };
            eventPatch.end = { date: dateToCalString(tomorrow) };
          } else {
            eventPatch.extendedProperties.shared[edit.field] = edit.value;
          }
          timestamps[edit.field] = action.timestamp;
          eventPatch.extendedProperties.shared.timestamps = JSON.stringify(
            timestamps
          );
        } else {
          serverHasUpdate = true;
        }
      });
      if (serverHasUpdate) {
        // queue up a refresh from the server since we saw newer timestamps.
        reducer.store.dispatch(reducer.syncTodosAction(listId, [], Date.now()));
      }
      return patchEventAPI({
        calendarId: listId,
        eventId: todoId,
        resource: eventPatch,
      });
    })
    .then((response) => {
      reducer.store.dispatch(
        reducer.editTodoFieldsSuccessAction(
          listId,
          todoId,
          action.edits,
          action.timestamp
        )
      );
    })
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      let edits = action.edits.map((edit) => {
        return { ...edit, value: message };
      });
      reducer.store.dispatch(
        reducer.editTodoFieldsErrorAction(
          listId,
          todoId,
          edits,
          action.timestamp
        )
      );
      throw err;
    });
}

export function executeAllEditActions(list) {
  return list.reduce((acc, v) => {
    return acc.then(() => editTodoFields(v));
  }, Promise.resolve());
}

export function moveTodo(action) {
  const state = reducer.getUserState(reducer.store.getState());
  let editActionList = [];
  for (var key in state[action.list_id].todos) {
    var item = state[action.list_id].todos[key];
    var edits = [];
    if (item.extendedProperties.shared.timestamps.next === action.timestamp) {
      edits.push({ field: "next", value: item.extendedProperties.shared.next });
    }
    if (item.extendedProperties.shared.timestamps.prev === action.timestamp) {
      edits.push({ field: "prev", value: item.extendedProperties.shared.prev });
    }
    if (edits.length > 0) {
      var editAction = reducer.editTodoFieldsPendingAction(
        action.list_id,
        key,
        edits,
        action.timestamp
      );
      editActionList.push(editAction);
    }
  }
  return executeAllEditActions(editActionList)
    .then((response) => {
      reducer.store.dispatch(
        reducer.moveTodoItemSuccessAction(
          action.list_id,
          action.id,
          action.prev_id
        )
      );
    })
    .catch((err) => {
      var message = getErrorMessages(err) || err;
      reducer.store.dispatch(
        reducer.moveTodoItemErrorAction(
          action.list_id,
          action.id,
          action.prev_id,
          message
        )
      );
      throw err;
    });
}

/**
 * Calls a gapi function and builds an array elements from the results.
 *
 * If necessary, the gapi function is called repeatedly with the 'pageToken' to
 * get more elements from a result that spans multiple pages.
 *
 * If the requestSyncToken is specified and there are no updates, this function
 * returns 'undefined' for the listOfItems.
 *
 * @param gapiFunction  a gapi function to call
 * @param params  parameters to be passed to gapiFunction; specify no params
 *   with an empty object.
 * @param requestSyncToken  a saved syncToken to be used in the gapi call, can
 *   be undefined.
 *
 * @return {listOfItems: [], syncToken: 'string'}
 */
async function getPageableResult(gapiFunction, params, requestSyncToken) {
  var listOfItems = undefined;
  requestSyncToken && (params.syncToken = requestSyncToken);

  while (true) {
    let response;
    try {
      response = await gapiFunction(params);
    } catch (e) {
      if (e.status === 410) {
        // 410 status "Gone" indicates the sync token is no longer valid.
        // Ask again without the sync token.
        delete params.syncToken;
        continue;
      }
    }
    const { nextSyncToken, nextPageToken, items } = response.result;

    if (requestSyncToken) {
      requestSyncToken = undefined;
      delete params.syncToken;

      if (items.length) {
        // There are updates to a previously synced list; resync from scratch.
        continue;
      }

      // No items returned when using the requestSyncToken:  Return {
      // listOfItems: undefined } to signal the list is already synced.
    } else {
      listOfItems = listOfItems || [];
      listOfItems.push(...items);
    }

    if (nextPageToken) {
      params.pageToken = nextPageToken;
      params.syncToken = nextSyncToken;
    } else {
      return { listOfItems: listOfItems, syncToken: nextSyncToken };
    }
  }
}

export function makeListObjectsFromCurrentAccount(current) {
  var allLists = current.AllLists;
  return allLists.map((listId) => {
    return {
      id: listId,
      summary: current[listId].listMetadata.summary,
      description: encodeDescriptionData(
        current[listId].anchor_id,
        current[listId].creator
      ),
    };
  });
}

export function syncAllLists() {
  const tokenKey = "AllTodoLists.syncToken";
  const nextSyncToken = localStorage.getItem(tokenKey);

  return getPageableResult(
    window.gapi.client.calendar.calendarList.list,
    {},
    nextSyncToken
  )
    .then(({ listOfItems, syncToken }) => {
      if (syncToken) {
        localStorage.setItem(tokenKey, syncToken);
      } else {
        localStorage.removeItem(tokenKey);
      }
      if (listOfItems) {
        const allLists = listOfItems.filter((x) =>
          x.summary.startsWith(TODO_LIST_PREFIX)
        );
        // console.log(
        // "lists.syncAllLists() <" +
        // allLists.map((x) => formatSummary(x.summary)) +
        // ">"
        // );
        reducer.store.dispatch(reducer.syncListsAction(allLists));
      } else {
        reducer.store.dispatch((dispatch, getState) => {
          const account = reducer.getUserState(getState());
          account &&
            dispatch(
              reducer.syncListsAction(
                makeListObjectsFromCurrentAccount(account)
              )
            );
        });
      }
    })
    .catch((error) => {
      console.log("lists.syncAllLists: handling gapi error: " + error);
      // TODO: reducer.store.dispatch(reducer.createListErrorAction('All Lists', error));
    });
}

export function authStateListener(state) {
  if (state.currentAccount) {
    // We have signed in as a new account, trigger a reload of all the lists
    syncAllLists();
  }
}

export function processItemsFromLocalStorage(listId) {
  reducer.store.dispatch(reducer.syncTodosAction(listId, [], Date.now()));
}

function getDueDateFromCalendarEvent(event) {
  if (event.start.date === event.end.date) {
    return event.start.date;
  }
  return undefined;
}

export function syncTodos(action) {
  const listId = action.list_id;
  const tokenKey = listId + ".syncToken";
  const nextSyncToken = localStorage.getItem(tokenKey);

  return getPageableResult(
    window.gapi.client.calendar.events.list,
    { calendarId: listId },
    nextSyncToken
  )
    .then(({ listOfItems, syncToken }) => {
      if (syncToken) {
        localStorage.setItem(tokenKey, syncToken);
      } else {
        localStorage.removeItem(tokenKey);
      }
      if (listOfItems) {
        listOfItems.forEach((event) => {
          event.description = event.summary;
          event.extendedProperties.shared.done =
            event.extendedProperties.shared.done === "true";
          event.extendedProperties.shared.starred =
            event.extendedProperties.shared.starred === "true";
          event.extendedProperties.shared.dueDate = getDueDateFromCalendarEvent(
            event
          );
          event.extendedProperties.shared.timestamps = parseTimestamps(event);
        });
        // console.log("lists.syncTodos:" + listOfItems.map((x) => x.summary));
        // console.log("lists.syncTodos:", ...listOfItems);
        reducer.store.dispatch(
          reducer.syncTodosAction(listId, listOfItems, Date.now())
        );
      } else {
        processItemsFromLocalStorage(listId);
      }
      syncAccessControls(action);
    })
    .catch((error) => {
      console.log("lists.syncTodos: handling error: " + error);
      // TODO: reducer.store.dispatch(reducer.syncTodosErrorAction('listId: ' + listId, error));
    });
}

function syncAccessControls(action) {
  const listId = action.list_id;

  return getPageableResult(window.gapi.client.calendar.acl.list, {
    calendarId: listId,
  })
    .then(({ listOfItems, syncToken }) => {
      if (listOfItems) {
        for (var i = 0; i < listOfItems.length; ++i) {
          const item = listOfItems[i];
          if (
            item.scope.type === "user" &&
            item.scope.value.indexOf("@group.calendar") === -1
          ) {
            reducer.store.dispatch(
              reducer.createSyncShareAction(listId, item.scope.value)
            );
          }
        }
      } else {
        console.log("ERROR reading list");
      }
    })
    .catch((error) => {
      console.log("lists.syncAccessControls: handling error: " + error);
      // TODO: reducer.store.dispatch(reducer.syncTodosErrorAction('listId: ' + listId, error));
    });
}

export function shareList(action) {
  const listId = action.id;

  return window.gapi.client.calendar.acl
    .insert({
      calendarId: listId,
      scope: { type: "user", value: action.email },
      role: "owner",
    })
    .then(({ result, syncToken }) => {
      reducer.store.dispatch(reducer.createShareAction(listId, action.email));
    })
    .catch((error) => {
      console.log("lists.shareList: handling error: " + error);
      // TODO: reducer.store.dispatch(reducer.syncTodosErrorAction('listId: ' + listId, error));
    });
}

export function unshareList(action) {
  const listId = action.id;

  return window.gapi.client.calendar.acl
    .delete({ calendarId: listId, ruleId: "user:" + action.email })
    .then(({ result, syncToken }) => {
      reducer.store.dispatch(reducer.createUnshareAction(listId, action.email));
    })
    .catch((error) => {
      console.log("lists.unshareList: handling error: " + error);
      // TODO: reducer.store.dispatch(reducer.syncTodosErrorAction('listId: ' + listId, error));
    });
}
