import firebase from "firebase";

var firebaseConfig = {
	apiKey: "AIzaSyBWOYdZAlHEIDX7Qy7PVUIztjffSDK0gn0",
	authDomain: "fir-todo-deea7.firebaseapp.com",
	databaseURL: "https://fir-todo-deea7.firebaseio.com",
	projectId: "fir-todo-deea7",
	storageBucket: "fir-todo-deea7.appspot.com",
	messagingSenderId: "994394868670",
	appId: "1:994394868670:web:e41d92fa1b76ce8a8207ef"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
