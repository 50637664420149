import * as reducer from "./reducers";
import firebase from "./firebase";

// Saves the messaging device token to the datastore.
function saveMessagingDeviceToken(email) {
  firebase
    .messaging()
    .getToken()
    .then(function (currentToken) {
      if (currentToken) {
        // console.log("Got FCM device token:", currentToken);
        // Saving the Device Token to the datastore.
        firebase
          .firestore()
          .collection("users")
          .doc(email)
          .collection("fcmTokens")
          .doc(currentToken)
          .set({ uid: firebase.auth().currentUser.uid });
      } else {
        // Need to request permissions to show notifications.
        requestNotificationsPermissions(email);
      }
    })
    .catch(function (error) {
      console.error("Unable to get messaging token.", error);
    });
}

// Requests permission to show notifications.
function requestNotificationsPermissions(email) {
  // console.log("Requesting notifications permission...");
  firebase
    .messaging()
    .requestPermission()
    .then(function () {
      // Notification permission granted.
      saveMessagingDeviceToken(email);
    })
    .catch(function (error) {
      console.error("Unable to get permission to notify.", error);
    });
}

const signIn = () => {
  var options = {
    ux_mode: UX_MODE,
  };
  return window.gapi.auth2.getAuthInstance().signIn(options);
};

const checkIfSignedIn = () => {
  const auth = window.gapi.auth2.getAuthInstance();
  if (auth && auth.isSignedIn.get()) {
    let googleUser = auth.currentUser.get();
    // get the credentials from the google auth response
    let idToken = googleUser.getAuthResponse().id_token;
    let creds = firebase.auth.GoogleAuthProvider.credential(idToken);
    // auth in the user
    firebase
      .auth()
      .signInWithCredential(creds)
      .then((user) => {
        if (user) {
          // console.log(user);
          // console.log(user.user.displayName);
          // console.log(user.user.email);
          // console.log(user.user.photoURL);
          let u = user.user;
          firebase
            .firestore()
            .collection("users")
            .doc(u.email)
            .set({
              name: u.displayName,
              email: u.email,
              photo: u.photoURL,
              activity_timestamp: new Date().getTime(),
            })
            .catch((error) => {
              // TODO: Surface this error state in the UI.
              console.error(error);
            });
          saveMessagingDeviceToken(u.email);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const currentUser = auth.currentUser.get();
    const email = currentUser.getBasicProfile().getEmail();
    reducer.store.dispatch(reducer.signInAction(email));
    return true;
  }
  return false;
};

export const signInButtonPressed = () => {
  reducer.store.dispatch(reducer.authInProgressAction());

  if (!checkIfSignedIn()) {
    signIn().then(
      (success) =>
        reducer.store.dispatch(
          reducer.signInAction(success.getBasicProfile().getEmail())
        ),
      (err) => reducer.store.dispatch(reducer.authErrorAction(err))
    );
  }
};

const signOut = () => {
  window.gapi.auth2.getAuthInstance().disconnect();
  return window.gapi.auth2.getAuthInstance().signOut();
};

export const signOutAsync = () => {
  reducer.store.dispatch((dispatch) => {
    dispatch(reducer.authInProgressAction());

    signOut().then(
      (success) => dispatch(reducer.signOutAction()),
      (err) => dispatch(reducer.authErrorAction(err))
    );
  });
};

const UX_MODE = "popup"; // 'redirect' or 'popup'

function initClient(dispatch) {
  // Client ID and API key from the Developer Console
  const CLIENT_ID =
    "763544583174-t5sm61vciqts2tblgk4uvt1p2tbk64r0.apps.googleusercontent.com";
  const API_KEY = "AIzaSyCq7_Dpub4tn8KP6RLMQeeDXZNFnRja5DI";

  // Array of API discovery doc URLs for APIs used by the quickstart
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES =
    "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

  window.gapi.client
    .init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
      ux_mode: UX_MODE,
    })
    .then(
      (res) => {
        dispatch(reducer.authErrorAction("Ready to sign in, click!"));
        checkIfSignedIn();
      },
      (errObj) => {
        dispatch(
          reducer.authErrorAction(
            "Google API initClient failed: " + JSON.stringify(errObj)
          )
        );
      }
    );
}

function handleClientLoad() {
  reducer.store.dispatch((dispatch) => {
    dispatch(reducer.authInProgressAction());

    window.gapi.load("client:auth2", {
      callback: () => initClient(dispatch),
      onerror: (errObj) =>
        dispatch(
          reducer.authErrorAction(
            "GoogleApi: error loading auth2 API: " + JSON.stringify(errObj)
          )
        ),
      timeout: 5000, // 5 seconds
      // ontimeout is called:
      //   when the first arg to gapi.load is not recognized.
      ontimeout: () =>
        dispatch(reducer.authErrorAction("GoogleAPI: auth2 API timed out")),
    });
  });
}

export function gapiStateListener(state) {
  // console.log("GAPI state: " + JSON.stringify(state, undefined, 2));
  if (state.isOnline) {
    handleClientLoad();
  }
}
