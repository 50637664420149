import * as reducer from "./reducers";

export function loadGapiJavascript(
  scriptUrl = "https://apis.google.com/js/api.js",
  store = reducer.store
) {
  store.dispatch((dispatch) => {
    dispatch(reducer.loadGAPIAction());

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptUrl;

    window.addEventListener(
      "online",
      () => {
        dispatch(reducer.onlineGAPIAction());
      },
      false
    );
    window.addEventListener(
      "offline",
      () => {
        dispatch(reducer.offlineGAPIAction());
      },
      false
    );
    script.onload = () => {
      if (navigator.onLine) {
        dispatch(reducer.onlineGAPIAction());
      }
    };

    script.onerror = (e) => {
      // This handler will catch:
      //   name resolution failed, eg: https://INVALID
      //   404, eg: https://www.google.com/NOT_THERE
      script.onerror = function () {};
      dispatch(reducer.offlineGAPIAction(e.srcElement.src));
    };

    document.body.appendChild(script);
  });
}
