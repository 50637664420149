import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { TodoApp } from "./TodoApp";
import * as serviceWorker from "./serviceWorker";
import { store, subscribeTo } from "./reducers";
import { loadGapiJavascript } from "./gapi";
import { gapiStateListener } from "./auth";
import { authStateListener } from "./lists";
import { pendingActionsListener } from "./sync";

ReactDOM.render(
  <Provider store={store}>
    <TodoApp />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});

subscribeTo("gapi_state", gapiStateListener);
subscribeTo("auth", authStateListener);
subscribeTo("pendingActions", pendingActionsListener);
store.subscribe(() => {
  var nextState = store.getState();
  localStorage.setItem("state", JSON.stringify(nextState));
});
loadGapiJavascript();
