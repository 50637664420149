import * as reducer from './reducers';
import * as lists from './lists';

export function runSyncAction(action) {
  switch (action.type) {
    case "CREATE_LIST_PENDING":
      return lists.createList(action);
    case "DELETE_LIST_PENDING":
      return lists.deleteList(action);
    case "SYNC_LISTS_PENDING":
      return lists.syncAllLists();
    case "SYNC_TODOS_PENDING":
      return lists.syncTodos(action);
    case "CREATE_ANCHOR_PENDING":
      return lists.createAnchor(action);
    case "CREATE_TODO_PENDING":
      return lists.createTodo(action);
    case "EDIT_TODO_FIELD_PENDING":
      return lists.editTodoFields(action);
    case "MOVE_TODO_ITEM_PENDING":
      return lists.moveTodo(action);
    case "SHARE_LIST_PENDING":
      return lists.shareList(action);
    case "UNSHARE_LIST_PENDING":
      return lists.unshareList(action);
    case "SERVER_FIXUP_ANCHOR": {
      return lists.healAnchor(action);
    }
    default:
			return Promise.reject('UNRECOGNIZED OFFLINE ACTION: [' + action.type + ']');
  }
}

var working = false;

export function sync() {
	syncLogger.group('sync');

  var pendingActions = reducer.store.getState().pendingActions;
  if (!pendingActions || pendingActions.length === 0) {
		syncLogger.log('Early exit.');
    syncLogger.groupEnd();
		return new Promise(r => setTimeout(r, 200));
  }

	if(working) {
		syncLogger.log('Sync is busy.');
    syncLogger.groupEnd();
		return new Promise(r => setTimeout(r, 200));
  }

  working = true;
  try {
    var upcomingActions = [...pendingActions];
		syncLogger.previous('UPCOMING ACTIONS', ...upcomingActions);
    var action = upcomingActions.shift();
		syncLogger.current('CURRENT ACTION', action);

		return runSyncAction(action).then(success => {
        var remainingActions = [...reducer.store.getState().pendingActions];
        var completedAction = remainingActions.shift();
			syncLogger.current('COMPLETED ACTION', completedAction);
			syncLogger.next('NEXT ACTIONS', ...remainingActions);
			reducer.store.dispatch(reducer.setRemainingActionsAction(remainingActions));
        syncLogger.groupEnd();
        working = false;
		}).catch(error => {
			syncLogger.error('FAILED ACTION', action, error);
        syncLogger.groupEnd();
        console.error("FAILED ACTION", action, error);
        working = false;
        return new Promise((r) => setTimeout(r, 200));
      });
  } catch (err) {
		syncLogger.error('EXCEPTION THROWN: ' + err.message);
    syncLogger.groupEnd();
    console.error("EXCEPTION THROWN: " + err.message);
    working = false;
		return new Promise(r => setTimeout(r, 200));
  }
}

export function syncAll() {
  sync().then(success => {
    let pendingActions = reducer.store.getState().pendingActions;
    if (pendingActions && pendingActions.length > 0) {
      window.setTimeout(() => syncAll(), reducer.store.getState().ui.syncDelay);
    }
  });
}

export function pendingActionsListener(pendingActions) {
  let updateCountAction = reducer.updateSyncItemCountAction((pendingActions && pendingActions.length) || 0);
  if (updateCountAction.count !== reducer.store.getState().ui.syncItemCount) {
    reducer.store.dispatch(updateCountAction);
  }
  if (window.gapi && pendingActions && reducer.store.getState().ui.syncDelay === 0) {
    if (!working) {
      syncAll();
    } else {
      console.log("sync: Skip triggering more work; already working.");
    }
  }
}

const LOGGING_ON = false;
const syncLogger = {
  colours: {
		prevState: '#9E9E9E',
		action: '#03A9F4',
		nextState: '#4CAF50',
		error: '#F20404',
  },

	group: title => { if (LOGGING_ON) console.group('%c ' + title + ' ' + syncLogger.formatTime(new Date()), 'color: gray; font-weight: lighter;') },
	groupEnd: () => { if (LOGGING_ON) console.groupEnd() },

	log: message => { if (LOGGING_ON) console.log(message) },

	previous: (message, actions) => { if (LOGGING_ON) console.log('%c ' + message, 'color: ' + syncLogger.colours.prevState + '; font-weight: bold;', actions) },

	current: (message, action) => { if (LOGGING_ON) console.log('%c ' + message, 'color: ' + syncLogger.colours.action + '; font-weight: bold;', action) },

	next: (message, actions) => { if (LOGGING_ON) console.log('%c ' + message, 'color: ' + syncLogger.colours.nextState + '; font-weight: bold;', actions) },

	error: (message, action, error) => { if (LOGGING_ON) console.error('%c ' + message, 'color: ' + syncLogger.colours.error + '; font-weight: bold;', action, error) },


	repeat: (str, times) => (new Array(times + 1)).join(str),

	pad: (num, maxLength) => syncLogger.repeat('0', maxLength - num.toString().length) + num,

	formatTime: time => `${syncLogger.pad(time.getHours(), 2)}:${syncLogger.pad(time.getMinutes(), 2)}:${syncLogger.pad(time.getSeconds(), 2)}.${syncLogger.pad(time.getMilliseconds(), 3)}`,

}
