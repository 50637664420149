import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { applyMiddleware, createStore, compose } from "redux";
import { makeListObjectsFromCurrentAccount, formatSummary } from "./lists";
import deepEqual from "deep-equal";
import firebase from "./firebase";

// -------------------

// gapi_state records what state the Google API is in.
//   no parameters: return unloaded state
//   action = { type: "LOAD" }: try to load the Google API
//   action = { type: "GAPI_WAITING" }: loading in progress
//   action = { type: "ONLINE" }: loaded successfully
//   action = { type: "OFFLINE" }: failed to load, presume we are offline
export function gapi_state(
  state = { isOnline: false, isLoading: false, message: "Ready to load" },
  action
) {
  switch (action.type) {
    case "LOAD":
      return { ...state, isOnline: false, isLoading: true, message: undefined };
    case "GAPI_WAITING":
      return {
        ...state,
        isOnline: false,
        isLoading: true,
        message: "Loading Google API",
      };
    case "ONLINE":
      return { ...state, isOnline: true, isLoading: false, message: undefined };
    case "OFFLINE":
      return {
        ...state,
        isOnline: false,
        isLoading: false,
        message: "Failed to load URL: " + action.url,
      };
    default:
      return state;
  }
}

export function loadGAPIAction() {
  return { type: "LOAD" };
}

export function waitingGAPIAction() {
  return { type: "GAPI_WAITING" };
}

export function onlineGAPIAction() {
  return { type: "ONLINE" };
}

export function offlineGAPIAction(err) {
  return { type: "OFFLINE", url: err };
}

// -------------------

// auth is expecting one of these things:
//   no parameters: return initial state
//   action = { type: "SIGN_OUT" }: sign the user out
//   action = { type: "SIGN_IN", email: <addr> }: sign the user in
//   action = { type: "AUTH_WAITING", email: <addr> }: sign the user in
//   action = { type: "AUTH_ERROR" }: display an error message
export function auth(
  state = { currentAccount: undefined, signedIn: false },
  action
) {
  switch (action.type) {
    case "AUTH_WAITING":
      return {
        ...state,
        currentAccount: undefined,
        signedIn: undefined,
        authMessage: undefined,
      };
    case "AUTH_ERROR":
      return {
        ...state,
        currentAccount: undefined,
        signedIn: false,
        authMessage: action.error.error,
      };
    case "SIGN_IN":
      return {
        ...state,
        currentAccount: action.email,
        signedIn: true,
        authMessage: undefined,
      };
    case "SIGN_OUT":
      return {
        ...state,
        currentAccount: undefined,
        signedIn: false,
        authMessage: undefined,
      };
    default:
      return state;
  }
}

export function signInAction(email) {
  return { type: "SIGN_IN", email: email };
}

export function signOutAction() {
  return { type: "SIGN_OUT" };
}

export function authInProgressAction() {
  return { type: "AUTH_WAITING" };
}

export function authErrorAction(err) {
  return { type: "AUTH_ERROR", error: err };
}

// -------------------

// user_lists is expecting one of these things:
// no parameters: return initial state (no todo lists)
// action = { type: "CREATE_LIST_PENDING", id: "Todo List id Goes Here", name: "todo list name", creator: "email@somewhere.com", offline: true }
// action = { type: "CREATE_LIST_SUCCESS", id: "Todo List id Goes Here", server_id: "id from calendar here" }
// action = { type: "CREATE_LIST_ERROR", id: "Todo List id Goes Here", message: "error message" }
// action = { type: "SHARE_LIST_PENDING", id: "Todo List id Goes Here", email: "share with this email", creator: "email", offline: true }
// action = { type: "SHARE_LIST_SUCCESS", id: "Todo List id Goes Here", email: "share with this email" }
// action = { type: "UNSHARE_LIST_PENDING", id: "Todo List id Goes Here", email: "share with this email", offline: true }
// action = { type: "UNSHARE_LIST_SUCCESS", id: "Todo List id Goes Here", email: "share with this email" }
// action = { type: "SHARE_LIST_ERROR", id: "Todo List id Goes Here", message: "error message" }
// action = { type: "SYNC_SHARE_LIST_SUCCESS", id: "Todo List id Goes Here", email: "share with this email" }
// action = { type: "SYNC_LISTS_PENDING", lists: [ "all lists are here" ], offline: true }
// action = { type: "SYNC_LISTS_SUCCESS", lists: [ "all lists are here" ] }
// action = { type: "DELETE_LIST_PENDING", id: "Todo List id", offline: true }
// action = { type: "DELETE_LIST_SUCCESS", id: "Todo List id" }
// action = { type: "DELETE_LIST_ERROR", id: "Todo List id", message: "error message" }
// action = { type: "SELECT_LIST", id: "Todo List id Goes Here" }
export function user_lists(state = { AllLists: [] }, action) {
  var i = action && action.id ? state.AllLists.indexOf(action.id) : -1;
  var ret;
  switch (action.type) {
    case "DELETE_LIST_PENDING": {
      const pending = { ...state.PendingLists } || {};
      if (state[action.id]) {
        pending[action.id] = true;
      }
      ret = {
        ...state,
        AllLists: state.AllLists.filter((v, j, a) => {
          return j !== i;
        }),
        PendingLists: pending,
      };
      delete ret[action.id];
      if (ret.currentList === action.id) {
        ret.currentList = undefined;
      }
      return ret;
    }
    case "DELETE_LIST_SUCCESS": {
      const pending = { ...state.PendingLists } || {};
      delete pending[action.id];
      return { ...state, PendingLists: pending };
    }
    case "DELETE_LIST_ERROR": {
      const pending = { ...state.PendingLists } || {};
      pending[action.id] = action.message;
      return { ...state, PendingLists: pending };
    }
    case "CREATE_LIST_PENDING": {
      if (i === -1) {
        const pending = { ...state.PendingLists } || {};
        pending[action.id] = true;
        ret = {
          ...state,
          currentList: action.id,
          AllLists: state.AllLists.concat(action.id),
          PendingLists: pending,
        };
        ret[action.id] = {
          ...ret[action.id],
          listMetadata: { summary: action.name },
          creator: action.creator,
          shared: [action.creator],
          anchor_id: action.anchor_id,
        };
        return ret;
      }
      return state;
    }
    case "CREATE_LIST_SUCCESS": {
      const pending = { ...state.PendingLists } || {};
      if (pending[action.id]) {
        delete pending[action.id];
      }
      ret = { ...state, PendingLists: pending };
      if (ret[action.id]) {
        ret[action.server_id] = ret[action.id];
        delete ret[action.id];
      }
      if (i >= 0 && ret.AllLists[i] !== action.server_id) {
        ret.AllLists = [...ret.AllLists];
        ret.AllLists[i] = action.server_id;
      }
      if (ret.currentList === action.id) {
        ret.currentList = action.server_id;
      }
      return ret;
    }
    case "CREATE_LIST_ERROR": {
      const pending = { ...state.PendingLists } || {};
      pending[action.id] = action.message;
      return { ...state, PendingLists: pending };
    }
    case "SHARE_LIST_SUCCESS":
    case "SYNC_SHARE_LIST_SUCCESS": {
      if (i !== -1) {
        const pendingShares =
          (state[action.id].pendingShares && {
            ...state[action.id].pendingShares,
          }) ||
          {};
        delete pendingShares[action.email];
        const shared =
          (state[action.id].shared && [...state[action.id].shared]) || [];
        if (!shared.includes(action.email)) {
          shared.push(action.email);
        }
        ret = { ...state };
        ret[action.id] = {
          ...ret[action.id],
          shared: shared,
          pendingShares: pendingShares,
        };
        return ret;
      }
      return state;
    }
    case "SHARE_LIST_PENDING": {
      if (i !== -1) {
        ret = { ...state };
        if (!state[action.id].creator) {
          ret[action.id] = { ...ret[action.id] };
          ret[action.id].creator = action.creator;
        }
        const shared =
          (state[action.id].shared && [...state[action.id].shared]) || [];
        const pendingShares =
          (state[action.id].pendingShares && {
            ...state[action.id].pendingShares,
          }) ||
          {};
        if (!shared.includes(action.email)) {
          shared.push(action.email);
          pendingShares[action.email] = true;
        }
        ret[action.id] = {
          ...ret[action.id],
          shared: shared,
          pendingShares: pendingShares,
        };
        return ret;
      }
      return state;
    }

    case "UNSHARE_LIST_PENDING": {
      if (i !== -1) {
        let shared =
          (state[action.id].shared && [...state[action.id].shared]) || [];
        const pendingUnshares =
          (state[action.id].pendingUnshares && {
            ...state[action.id].pendingUnshares,
          }) ||
          {};
        if (shared.includes(action.email)) {
          //shared = shared.filter(x => action.email !== x);
          pendingUnshares[action.email] = true;
        }
        ret = { ...state };
        ret[action.id] = {
          ...ret[action.id],
          shared: shared,
          pendingUnshares: pendingUnshares,
        };
        return ret;
      }
      return state;
    }
    case "UNSHARE_LIST_SUCCESS": {
      if (i !== -1) {
        let shared =
          (state[action.id].shared && [...state[action.id].shared]) || [];
        const pendingUnshares =
          (state[action.id].pendingUnshares && {
            ...state[action.id].pendingUnshares,
          }) ||
          {};
        shared = shared.filter((x) => action.email !== x);
        delete pendingUnshares[action.email];
        ret = { ...state };
        ret[action.id] = {
          ...ret[action.id],
          shared: shared,
          pendingUnshares: pendingUnshares,
        };
        return ret;
      }
      return state;
    }
    case "SYNC_LISTS_SUCCESS":
    case "SYNC_LISTS_PENDING": {
      ret = {
        ...state,
        AllLists: action.lists.map((x) => x.id),
        PendingLists: {},
      };
      for (var j = 0; j < action.lists.length; ++j) {
        let params = {};
        try {
          params = JSON.parse(action.lists[j].description);
        } catch (e) {
          /* ignore parse errors */
          if (action.lists[j].description !== undefined) {
            console.log(
              "JSON Parse error for calendar metadata: " +
                action.lists[j].description
            );
          }
        }
        ret[action.lists[j].id] = {
          ...ret[action.lists[j].id],
          anchor_id: params.anchor_id,
          creator: params.creator,
          listMetadata: { summary: action.lists[j].summary },
        };
        if (!ret[action.lists[j].id].todos && params.anchor_id) {
          let properties = {
            shared: {
              starred: false,
              done: false,
              prev: params.anchor_id,
              next: params.anchor_id,
              timestamps: makeTodoTimestampsFor(0),
            },
          };
          ret[action.lists[j].id].todos = {};
          ret[action.lists[j].id].todos[params.anchor_id] = {
            description: ANCHOR,
            extendedProperties: properties,
          };
        }
        if (action.type === "SYNC_LISTS_PENDING") {
          ret.PendingLists[action.lists[j].id] = true;
        }
      }
      if (!ret.currentList && action.lists.length > 0) {
        ret.currentList = action.lists[0].id;
      }
      return ret;
    }
    case "SELECT_LIST": {
      if (i === -1 && action.id.length !== 1) {
        return state;
      }
      return { ...state, currentList: action.id };
    }
    case "RESET_LIST_ORDER": {
      const ret = { ...state };
      ret.AllLists = action.order;
      return ret;
    }
    case "REORDER_LISTS": {
      const ret = { ...state };
      ret.AllLists = [...ret.AllLists];
      const value = ret.AllLists.splice(action.oldIndex, 1)[0];
      ret.AllLists.splice(action.newIndex, 0, value);
      writeListOfListsOrder(action.email, ret.AllLists, action);
      return ret;
    }
    default:
      return state;
  }
}

function notify_on_user_lists(account, state, action) {
  switch (action.type) {
    case "SHARE_LIST_SUCCESS": {
      let url = "https://www.google.com/calendar/render?cid=" + action.id;
      let listName = state[action.id].listMetadata.summary;
      sendNotifications(
        account,
        [action.email],
        listName + " shared",
        "NAME shared '" + listName + "' with you!",
        url
      );
      return;
    }
    default:
      return;
  }
}

export function createPendingListAction(id, name, anchor_id, creator) {
  return {
    type: "CREATE_LIST_PENDING",
    id: id,
    name: name,
    anchor_id: anchor_id,
    creator: creator,
    offline: true,
  };
}

export function createListErrorAction(id, message) {
  return { type: "CREATE_LIST_ERROR", id: id, message: message };
}

export function createListAction(pendingId, serverSideId) {
  return {
    type: "CREATE_LIST_SUCCESS",
    id: pendingId,
    server_id: serverSideId,
  };
}

export function createPendingShareAction(id, email, creator) {
  return {
    type: "SHARE_LIST_PENDING",
    id: id,
    email: email,
    creator: creator,
    offline: true,
  };
}

export function createShareAction(id, email) {
  return { type: "SHARE_LIST_SUCCESS", id: id, email: email };
}
export function createPendingUnshareAction(id, email) {
  return { type: "UNSHARE_LIST_PENDING", id: id, email: email, offline: true };
}

export function createSyncShareAction(id, email) {
  return { type: "SYNC_SHARE_LIST_SUCCESS", id: id, email: email };
}

export function createUnshareAction(id, email) {
  return { type: "UNSHARE_LIST_SUCCESS", id: id, email: email };
}

export function deleteListPendingAction(id, shared) {
  return { type: "DELETE_LIST_PENDING", id, shared, offline: true };
}

export function deleteListErrorAction(id, message) {
  return { type: "DELETE_LIST_ERROR", id: id, message: message };
}

export function deleteListAction(id, shared) {
  return { type: "DELETE_LIST_SUCCESS", id, shared };
}

export function syncListsPendingAction(listOfListObjects) {
  return {
    type: "SYNC_LISTS_PENDING",
    lists: listOfListObjects,
    offline: true,
  };
}

export function syncListsAction(listOfListObjects) {
  return { type: "SYNC_LISTS_SUCCESS", lists: listOfListObjects };
}

export function selectListAction(id) {
  return { type: "SELECT_LIST", id: id };
}

export function reorderListsAction(email, oldIndex, newIndex) {
  return { type: "REORDER_LISTS", email, oldIndex, newIndex };
}

export function resetListOfListsOrderAction(order) {
  return { type: "RESET_LIST_ORDER", order };
}

// -------------------

export const ANCHOR = "⚓";

export function getNext(itemsState, id) {
  return itemsState[id].extendedProperties.shared.next;
}

export function getPrev(itemsState, id) {
  return itemsState[id].extendedProperties.shared.prev;
}

function setDescription(todos, itemId, description, timestamp) {
  const item = { ...todos[itemId] };
  const attrs = { ...item.extendedProperties.shared };
  if (
    item.description !== description &&
    (attrs.timestamps["description"] || 0) <= timestamp
  ) {
    attrs.timestamps = { ...attrs.timestamps };
    item.description = description;
    attrs.timestamps.description = timestamp;
    todos[itemId] = { ...item, extendedProperties: { shared: attrs } };
    return true;
  }
  return false;
}

function setField(todos, itemId, field, value, timestamp) {
  const item = todos[itemId];
  const attrs = { ...item.extendedProperties.shared };
  if (attrs[field] !== value && (attrs.timestamps[field] || 0) <= timestamp) {
    attrs.timestamps = { ...attrs.timestamps };
    attrs[field] = value;
    attrs.timestamps[field] = timestamp;
    todos[itemId] = { ...todos[itemId], extendedProperties: { shared: attrs } };
    return true;
  }
  return false;
}

function setStarred(todos, itemId, isStarred, timestamp) {
  return setField(todos, itemId, "starred", isStarred, timestamp);
}

function setDone(todos, itemId, isDone, timestamp) {
  return setField(todos, itemId, "done", isDone, timestamp);
}

function setDueDate(todos, itemId, dueDate, timestamp) {
  return setField(todos, itemId, "dueDate", dueDate, timestamp);
}

function setRepeats(todos, itemId, repeats, timestamp) {
  return setField(todos, itemId, "repeats", repeats, timestamp);
}

function setRepeatInterval(todos, itemId, repeatInterval, timestamp) {
  return setField(todos, itemId, "repeatInterval", repeatInterval, timestamp);
}

function setNext(todos, itemId, nextId, timestamp) {
  return setField(todos, itemId, "next", nextId, timestamp);
}

function setPrev(todos, itemId, prevId, timestamp) {
  return setField(todos, itemId, "prev", prevId, timestamp);
}

function setPending(todos, itemId, pendingFields, timestamp) {
  return setField(todos, itemId, "pending", pendingFields, timestamp);
}

async function sendNotifications(
  me,
  users,
  title,
  message,
  url = "https://todos.stockgamblers.com/"
) {
  let myInfo = await firebase.firestore().collection("users").doc(me).get();
  firebase
    .firestore()
    .collection("notifications")
    .doc()
    .set({
      to: users,
      title: title.replace(/NAME/g, myInfo.data().name),
      text: message.replace(/NAME/g, myInfo.data().name),
      picture: myInfo.data().photo,
      url,
    })
    .then((ok) => {
      // console.log("Notification sent to firebase");
    })
    // TODO: surface this error state?
    .catch((err) => {
      console.error("Notification write to firebase failed", err);
    });
}
// user_todos is expecting one of these things:
// no parameters: return initial state (no todo items)
// action = { type: "CREATE_ANCHOR_PENDING", id: "Anchor Item id", list_id: "List id", offline: true, timestamp: 999999 }
// action = { type: "CREATE_ANCHOR_SUCCESS", id: "Anchor Item id", list_id: "List id" }
// action = { type: "CREATE_ANCHOR_ERROR", id: "Anchor Item id", list_id: "List id", message: "error message" }
// action = { type: "CREATE_TODO_PENDING", id: "Todo Item id Goes Here", list_id: "List id",
//            description: "todo item description", offline: true, timestamp: 999999 }
// action = { type: "CREATE_TODO_SUCCESS", id: "Todo Item id Goes Here", list_id: "List id" }
// action = { type: "CREATE_TODO_ERROR", id: "Todo Item id Goes Here", list_id: "List id", message: "error message" }
// action = { type: "SYNC_TODOS_PENDING", list_id: "List id", todos: [ "all todos are here" ], offline: true }
// action = { type: "SYNC_TODOS_SUCCESS", list_id: "List id", todos: [ "all todos are here" ] }
// action = { type: "EDIT_TODO_FIELD_PENDING", list_id: "List id", id: "itemid", edits: [{field: "description", value: "item desc"}], timestamp: 999999};
// action = { type: "MOVE_TODO_ITEM_PENDING", list_id: "List id", id: "itemid", prev_id: "previtem", timestamp: 9999, offline: true }
// action =	{type: "MOVE_TODO_ITEM_SUCCESS", list_id: "List id", id: "itemid", prev_id: "anchor_id" };
// action = {type: "MOVE_TODO_ITEM_ERROR", list_id: "List id", id: "itemid", prev_id: "anchor_id", message: "oops" };
export function user_todos_unchecked(state = { todos: undefined }, action) {
  var ret;
  switch (action.type) {
    case "CREATE_ANCHOR_PENDING": {
      if (state.todos !== undefined) {
        // Anchor already exists
        return state;
      }
      const pending = { ...state.pendingTodos } || {};
      pending[action.id] = true;
      ret = {
        ...state,
        todos: {},
        pendingTodos: pending,
        anchor_id: action.id,
      };

      ret.todos[action.id] = {
        extendedProperties: { shared: { timestamps: {} } },
      };
      setDescription(ret.todos, action.id, ANCHOR, action.timestamp);
      setStarred(ret.todos, action.id, false, action.timestamp);
      setDone(ret.todos, action.id, false, action.timestamp);
      setNext(ret.todos, action.id, action.id, action.timestamp);
      setPrev(ret.todos, action.id, action.id, action.timestamp);
      setPending(ret.todos, action.id, {}, action.timestamp);
      return ret;
    }
    case "CREATE_TODO_PENDING": {
      const pending = { ...state.pendingTodos } || {};
      pending[action.id] = true;
      ret = { ...state, pendingTodos: pending };
      var prev = ret.anchor_id;
      var oldNext = getNext(ret.todos, prev);

      ret.todos = { ...ret.todos };

      ret.todos[action.id] = {
        extendedProperties: { shared: { timestamps: {} } },
      };
      setDescription(
        ret.todos,
        action.id,
        action.description,
        action.timestamp
      );
      setStarred(ret.todos, action.id, false, action.timestamp);
      setDone(ret.todos, action.id, false, action.timestamp);
      setNext(ret.todos, action.id, oldNext, action.timestamp);
      setPrev(ret.todos, action.id, prev, action.timestamp);
      setPending(ret.todos, action.id, {}, action.timestamp);

      setNext(ret.todos, prev, action.id, action.timestamp);
      setPrev(ret.todos, oldNext, action.id, action.timestamp);

      return ret;
    }
    case "CREATE_ANCHOR_SUCCESS":
    case "CREATE_TODO_SUCCESS": {
      const pending = { ...state.pendingTodos } || {};
      delete pending[action.id];
      return { ...state, pendingTodos: pending };
    }
    case "CREATE_ANCHOR_ERROR":
    case "CREATE_TODO_ERROR": {
      const pending = { ...state.pendingTodos } || {};
      pending[action.id] = action.message;
      return { ...state, pendingTodos: pending };
    }
    case "SYNC_TODOS_SUCCESS":
    case "SYNC_TODOS_PENDING": {
      const newTodos = {};
      const pendingTodos = {};
      var anchorId = null;
      for (const item of action.todos) {
        newTodos[item.id] = {
          description: item.description,
          extendedProperties: {
            shared: {
              done: item.extendedProperties.shared.done,
              starred: item.extendedProperties.shared.starred,
              next: item.extendedProperties.shared.next,
              prev: item.extendedProperties.shared.prev,
              dueDate: item.extendedProperties.shared.dueDate,
              repeats: item.extendedProperties.shared.repeats,
              repeatInterval: item.extendedProperties.shared.repeatInterval,
              pending: {},
              timestamps: { ...item.extendedProperties.shared.timestamps },
            },
          },
        };
        if (item.description === ANCHOR) {
          anchorId = item.id;
        }
        if (action.type === "SYNC_TODOS_PENDING") {
          pendingTodos[item.id] = true;
        }
      }
      if (action.type === "SYNC_TODOS_PENDING") {
        // if we didn't find the anchor tag, return the state unchanged.
        if (anchorId === null && action.todos.length === 0) {
          let hasPending = false;
          for (var key in state.todos) {
            pendingTodos[key] = true;
            hasPending = true;
          }
          if (hasPending) {
            return { ...state, pendingTodos: pendingTodos };
          }
        }
        if (anchorId === null) {
          return state;
        }
        return {
          ...state,
          todos: newTodos,
          anchor_id: anchorId,
          pendingTodos: pendingTodos,
        };
      }
      if (anchorId === null) {
        anchorId = state.anchor_id;
      }
      var todos =
        (state.todos && { ...state.todos }) ||
        user_todos_unchecked(state, {
          type: "CREATE_ANCHOR_PENDING",
          id: anchorId,
          offline: true,
          timestamp: 0,
        }).todos;
      for (var id in newTodos) {
        if (todos[id]) {
          // already have this item on the client side.
          // update the client if the server has newer timestamps
          const attrs = newTodos[id].extendedProperties.shared;
          const timestamps = attrs.timestamps;
          setDescription(
            todos,
            id,
            newTodos[id].description,
            timestamps.description
          );
          setStarred(todos, id, attrs.starred, timestamps.starred);
          setDone(todos, id, attrs.done, timestamps.done);
          setDueDate(todos, id, attrs.dueDate, timestamps.dueDate);
          setRepeats(todos, id, attrs.repeats, timestamps.repeats);
          setRepeatInterval(
            todos,
            id,
            attrs.repeatInterval,
            timestamps.repeatInterval
          );
          continue;
        } else {
          var origId = id;
          var startStrand = newTodos[id];
          todos[id] = startStrand;
          while (!todos[getPrev(newTodos, id)]) {
            var nextId = id;
            id = getPrev(newTodos, id);
            startStrand = newTodos[id];
            todos[id] = startStrand;
            setNext(todos, id, nextId, action.timestamp);
          }
          var startId = id;
          id = origId;
          var endStrand = newTodos[id];
          while (!todos[getNext(newTodos, id)]) {
            var prevId = id;
            id = getNext(newTodos, id);
            endStrand = newTodos[id];
            todos[id] = endStrand;
            setPrev(todos, id, prevId, action.timestamp);
          }
          var afterId = getPrev(newTodos, startId);
          var beforeId = getNext(todos, afterId);

          setNext(todos, afterId, startId, action.timestamp);
          setPrev(todos, beforeId, id, action.timestamp);
          setNext(todos, id, beforeId, action.timestamp);
        }
      }
      let ret = {
        ...state,
        todos: todos,
        anchor_id: anchorId,
        pendingTodos: pendingTodos,
      };
      // At this point, all todos are in the local list. Look for server side items
      // that have been moved by looking for prev pointers that are newer on the server
      // side than on the client. Then find the first element starting at that prev whose
      // next timestamp is older than the server's prev timestamp, and move the item
      // there.
      let count = 0;
      let limit = Object.keys(todos).length + 2;
      id =
        newTodos[anchorId] && newTodos[anchorId].extendedProperties.shared.next;
      while (id && id !== anchorId && count < limit) {
        count++;
        const localAttrs = todos[id].extendedProperties.shared;
        const localTimestamps = localAttrs.timestamps;
        const serverAttrs = newTodos[id].extendedProperties.shared;
        const serverTimestamps = serverAttrs.timestamps;
        if (
          localAttrs.prev !== serverAttrs.prev &&
          serverTimestamps.prev >= localTimestamps.prev
        ) {
          let insertAfter = serverAttrs.prev;
          let attrs = todos[insertAfter].extendedProperties.shared;
          let timestamps = attrs.timestamps;
          while (timestamps.next > serverTimestamps.prev) {
            attrs = todos[attrs.next].extendedProperties.shared;
            timestamps = attrs.timestamps;
            insertAfter = attrs.prev;
          }
          ret = user_todos_unchecked(
            ret,
            moveTodoItemPending(
              action.list_id,
              id,
              insertAfter,
              action.timestamp,
              true
            )
          );
        }
        id = serverAttrs.next;
      }

      return ret;
    }
    case "EDIT_TODO_FIELD_PENDING": {
      let todos = { ...state.todos };
      action.edits.forEach((edit) => {
        if (edit.field === "description") {
          let pending = {
            ...todos[action.id].extendedProperties.shared.pending,
            description: true,
          };
          if (setDescription(todos, action.id, edit.value, action.timestamp)) {
            setPending(todos, action.id, pending, action.timestamp);
          }
        } else {
          if (
            edit.field === "done" ||
            edit.field === "starred" ||
            edit.field === "dueDate" ||
            edit.field === "repeats" ||
            edit.field === "repeatInterval"
          ) {
            let pending = {
              ...todos[action.id].extendedProperties.shared.pending,
            };
            pending[edit.field] = true;
            if (
              setField(
                todos,
                action.id,
                edit.field,
                edit.value,
                action.timestamp
              )
            ) {
              setPending(todos, action.id, pending, action.timestamp);
            }
          } else {
            throw new Error("do not set " + edit.field);
          }
        }
      });
      return { ...state, todos: todos };
    }
    case "MOVE_TODO_ITEM_ERROR":
    case "EDIT_TODO_FIELD_ERROR": {
      let todos = { ...state.todos };
      action.edits.forEach((edit) => {
        let pending = { ...todos[action.id].extendedProperties.shared.pending };
        pending[edit.field] = edit.value;
        setPending(todos, action.id, pending, action.timestamp);
      });
      return { ...state, todos: todos };
    }
    case "EDIT_TODO_FIELD_SUCCESS": {
      let todos = { ...state.todos };
      let pending = { ...todos[action.id].extendedProperties.shared.pending };

      action.edits.forEach((edit) => {
        delete pending[edit.field];
      });
      todos[action.id] = { ...todos[action.id] };
      todos[action.id].extendedProperties = {
        ...todos[action.id].extendedProperties,
      };
      todos[action.id].extendedProperties.shared = {
        ...todos[action.id].extendedProperties.shared,
        pending: pending,
      };
      return { ...state, todos: todos };
    }
    case "MOVE_TODO_ITEM_PENDING": {
      let todos = { ...state.todos };
      let oldPrev = getPrev(todos, action.id);
      let oldNext = getNext(todos, action.id);
      let success = true;

      // unlink action.id from the list
      success &= setPrev(todos, oldNext, oldPrev, action.timestamp);
      let oldNextPending = {
        ...todos[oldNext].extendedProperties.shared.pending,
      };
      oldNextPending["prev"] = true;
      action.skip_pending ||
        setPending(todos, oldNext, oldNextPending, action.timestamp);
      success &= setNext(todos, oldPrev, oldNext, action.timestamp);
      let oldPrevPending = {
        ...todos[oldPrev].extendedProperties.shared.pending,
      };
      oldPrevPending["next"] = true;
      action.skip_pending ||
        setPending(todos, oldPrev, oldPrevPending, action.timestamp);

      // next, find the insertion point
      let prevsNext = getNext(todos, action.prev_id);
      let nextsPrev = getPrev(todos, prevsNext);

      // link action.id so that it's next is the desired prev's next,
      // and its prev is that node's old prev. It is now in the correct
      // spot, but not yet pointed at by the list itself.
      success &= setPrev(todos, action.id, nextsPrev, action.timestamp);
      success &= setNext(todos, action.id, prevsNext, action.timestamp);
      let pending = { ...todos[action.id].extendedProperties.shared.pending };
      pending["next"] = true;
      pending["prev"] = true;
      action.skip_pending ||
        setPending(todos, action.id, pending, action.timestamp);

      // link action.id so that it follows the desired prev, and precedes
      // its next, so it is correctly linked in place.
      success &= setPrev(todos, prevsNext, action.id, action.timestamp);
      let nextsPending = {
        ...todos[prevsNext].extendedProperties.shared.pending,
      };
      nextsPending["prev"] = true;
      action.skip_pending ||
        setPending(todos, prevsNext, nextsPending, action.timestamp);
      success &= setNext(todos, action.prev_id, action.id, action.timestamp);
      let prevsPending = {
        ...todos[action.prev_id].extendedProperties.shared.pending,
      };
      prevsPending["next"] = true;
      action.skip_pending ||
        setPending(todos, action.prev_id, prevsPending, action.timestamp);

      // if any part of that failed, abort the edit.
      if (!success) {
        return state;
      }
      return { ...state, todos: todos };
    }

    case "MOVE_TODO_ITEM_SUCCESS": {
      return state;
    }
    default:
      return state;
  }
}

export function makeTodoTimestampsFor(timestamp) {
  return {
    description: timestamp,
    done: timestamp,
    starred: timestamp,
    next: timestamp,
    prev: timestamp,
  };
}

/**
 * Return a new timestamp object based on the timestamps found in 'todoitem'
 * but replacing all keys and values with the ones found in 'overrides'.
 * @param {*} todoItem
 * @param {*} overrides
 */
export function mergeTodoTimestamps(todoItem, overrides) {
  var incoming = todoItem.extendedProperties.shared.timestamps;
  if (typeof incoming === "string") {
    try {
      incoming = JSON.parse(incoming);
    } catch (err) {
      incoming = {};
    }
  }
  return { ...incoming, ...overrides };
}

export function user_todos(state, action) {
  var ret = user_todos_unchecked(state, action);
  try {
    checkLinkedList(ret.todos);
  } catch (err) {
    var errs = [];
    if (ret.assertions) {
      errs = [...ret.assertions];
    }
    errs.push(err.message);
    ret = { ...ret, assertions: errs };
  }
  return ret;
}

function notify_on_user_todos(account, state, action) {
  switch (action.type) {
    case "CREATE_TODO_SUCCESS": {
      const users = state.shared.filter((x) => x !== account);
      if (users.length > 0) {
        sendNotifications(
          account,
          users,
          formatSummary(state.listMetadata.summary),
          "NAME added: " + state.todos[action.id].description
        );
      }
      return;
    }
    case "EDIT_TODO_FIELD_SUCCESS": {
      let doneEdited = false;
      let doneValue = false;

      action.edits.forEach((edit) => {
        if (edit.field === "done") {
          doneEdited = true;
          doneValue = edit.value;
        }
      });
      if (doneEdited) {
        const users = state.shared.filter((x) => x !== account);
        const status = doneValue ? " completed" : " re-opened";
        if (users.length > 0) {
          sendNotifications(
            account,
            users,
            "Todo" + status,
            "NAME " + status + ": " + state.todos[action.id].description
          );
        }
      }
      return;
    }
    default:
      return;
  }
}

function walk(todos, iterator) {
  const nElements = Object.keys(todos).length;
  const firstId = Object.keys(todos)[0];
  var nextCount = 1;
  var p = firstId;
  while (iterator(todos, p) !== firstId) {
    p = iterator(todos, p);
    ++nextCount;
    if (nextCount > nElements) {
      throw new Error(
        "More than " + nElements + " todo items.  Infinite loop?"
      );
    }
  }
  assert(
    nextCount === nElements,
    "nextCount = " + nextCount + " but should = " + nElements
  );
}

export function checkLinkedList(todos) {
  if (todos && Object.keys(todos).length > 0) {
    walk(todos, getNext);
    walk(todos, getPrev);
  }
}

export function assert(condition, message) {
  if (!condition) {
    throw new Error("Assertion failed. " + message);
  }
}

export function createPendingAnchorAction(id, listId, timestamp) {
  return {
    type: "CREATE_ANCHOR_PENDING",
    id: id,
    list_id: listId,
    offline: true,
    timestamp: timestamp,
  };
}

export function createAnchorAction(id, listId) {
  return { type: "CREATE_ANCHOR_SUCCESS", id: id, list_id: listId };
}

export function createAnchorErrorAction(id, listId, message) {
  return {
    type: "CREATE_ANCHOR_ERROR",
    id: id,
    list_id: listId,
    message: message,
  };
}

export function createPendingTodoAction(id, listId, description, timestamp) {
  return {
    type: "CREATE_TODO_PENDING",
    id: id,
    list_id: listId,
    description: description,
    offline: true,
    timestamp: timestamp,
  };
}

export function createTodoAction(id, listId) {
  return { type: "CREATE_TODO_SUCCESS", id: id, list_id: listId };
}

export function createTodoErrorAction(id, listId, message) {
  return {
    type: "CREATE_TODO_ERROR",
    id: id,
    list_id: listId,
    message: message,
  };
}

export function syncTodosPendingAction(listId, listOfTodos, timestamp) {
  return {
    type: "SYNC_TODOS_PENDING",
    list_id: listId,
    todos: listOfTodos,
    timestamp: timestamp,
    offline: true,
  };
}

export function syncTodosAction(listId, listOfTodos, timestamp) {
  return {
    type: "SYNC_TODOS_SUCCESS",
    list_id: listId,
    todos: listOfTodos,
    timestamp: timestamp,
  };
}

// editTodoFieldsPendingAction edits takes a list of field edits of the form
// {field: "fieldName", value: fieldValue} and applies those edits to the TODO
// item referenced by id inside listId. It is not intended to be used to edit
// the next/prev fields, but can be used for any other field name.
export function editTodoFieldsPendingAction(
  listId,
  id,
  listOfEdits,
  timestamp
) {
  return {
    type: "EDIT_TODO_FIELD_PENDING",
    list_id: listId,
    id: id,
    edits: listOfEdits,
    timestamp: timestamp,
    offline: true,
  };
}

export function moveTodoItemPending(
  listId,
  id,
  prevId,
  timestamp,
  skipPending
) {
  return {
    type: "MOVE_TODO_ITEM_PENDING",
    list_id: listId,
    id: id,
    prev_id: prevId,
    timestamp: timestamp,
    skip_pending: skipPending,
    offline: true,
  };
}

export function editTodoFieldsSuccessAction(
  listId,
  id,
  listOfEdits,
  timestamp
) {
  return {
    type: "EDIT_TODO_FIELD_SUCCESS",
    list_id: listId,
    id: id,
    edits: listOfEdits,
    timestamp: timestamp,
  };
}

export function editTodoFieldsErrorAction(listId, id, listOfEdits, timestamp) {
  return {
    type: "EDIT_TODO_FIELD_ERROR",
    list_id: listId,
    id: id,
    edits: listOfEdits,
    timestamp: timestamp,
  };
}

export function moveTodoItemSuccessAction(listId, id, prevId) {
  return {
    type: "MOVE_TODO_ITEM_SUCCESS",
    list_id: listId,
    id: id,
    prev_id: prevId,
  };
}

export function moveTodoItemErrorAction(listId, id, prevId, message) {
  return {
    type: "MOVE_TODO_ITEM_ERROR",
    list_id: listId,
    id: id,
    prev_id: prevId,
    message: message,
  };
}

const SYNC_DELAY = process.env.NODE_ENV === "production" ? 0 : 1000;
// -------------------
// ui is expecting one of these things:
// no parameters: return initial state (drawer is closed, delete list not showing)
// action = { type: "OPEN_DRAWER" }
// action = { type: "LIST_SELECT_LIST" } // also hide drawer if a list is picked
// action = { type: "CLOSE_DRAWER" }
// action = { type: "SHOW_EDIT_LIST_DIALOG" }
// action = { type: "HIDE_EDIT_LIST_DIALOG" }
// action = { type: "SHOW_DELETE_LIST_DIALOG" }
// action = { type: "HIDE_DELETE_LIST_DIALOG" }
// action = { type: "SYNC_COUNTER", count: n }
// action = { type: "SET_CURRENT_TASK", taskId: task_id }
// action = { type: "SET_SEARCH_TERM", searchTerm: searchTerm }
// action = { type: "SHOW_TASK_DETAILS" }
// action = { type: "HIDE_TASK_DTAILS" }
export function ui(
  state = {
    currentTask: undefined,
    searchTerm: undefined,
    drawerIsOpen: false,
    editListDialogIsShowing: false,
    deleteListDialogIsShowing: false,
    syncItemCount: 0,
    syncDelay: SYNC_DELAY,
    taskDetailsAreShowing: null,
  },
  action
) {
  switch (action.type) {
    case "OPEN_DRAWER": {
      return { ...state, drawerIsOpen: true };
    }
    case "SELECT_LIST": {
      return { ...state, drawerIsOpen: false };
    }
    case "CLOSE_DRAWER": {
      return { ...state, drawerIsOpen: false };
    }
    case "SHOW_EDIT_LIST_DIALOG": {
      return { ...state, editListDialogIsShowing: true };
    }
    case "HIDE_EDIT_LIST_DIALOG": {
      return { ...state, editListDialogIsShowing: false };
    }
    case "SHOW_DELETE_LIST_DIALOG": {
      return { ...state, deleteListDialogIsShowing: true };
    }
    case "HIDE_DELETE_LIST_DIALOG": {
      return { ...state, deleteListDialogIsShowing: false };
    }
    case "SYNC_COUNTER": {
      return { ...state, syncItemCount: action.count };
    }
    case "SET_CURRENT_TASK": {
      return { ...state, currentTask: action.taskId };
    }
    case "SET_SEARCH_TERM": {
      return { ...state, searchTerm: action.searchTerm };
    }
    case "SHOW_TASK_DETAILS": {
      return { ...state, taskDetailsAreShowing: { listId: action.list_id, taskId: action.task_id } };
    }
    case "HIDE_TASK_DETAILS": {
      return { ...state, taskDetailsAreShowing: null };
    }
    default:
      return state;
  }
}

export function openDrawerAction() {
  return { type: "OPEN_DRAWER" };
}

export function closeDrawerAction() {
  return { type: "CLOSE_DRAWER" };
}

export function showEditListDialogAction() {
  return { type: "SHOW_EDIT_LIST_DIALOG" };
}

export function hideEditListDialogAction() {
  return { type: "HIDE_EDIT_LIST_DIALOG" };
}

export function showDeleteListDialogAction() {
  return { type: "SHOW_DELETE_LIST_DIALOG" };
}

export function hideDeleteListDialogAction() {
  return { type: "HIDE_DELETE_LIST_DIALOG" };
}

export function updateSyncItemCountAction(n) {
  return { type: "SYNC_COUNTER", count: n };
}

export function setCurrentTaskAction(taskId) {
  return { type: "SET_CURRENT_TASK", taskId };
}

export function setSearchTerm(searchTerm) {
  return { type: "SET_SEARCH_TERM", searchTerm };
}

export function showTaskDetailsAction(list_id, task_id) {
  return { type: "SHOW_TASK_DETAILS", list_id, task_id };
}

export function hideTaskDetailsAction() {
  return { type: "HIDE_TASK_DETAILS" };
}

// -------------------

export function setRemainingActionsAction(actions) {
  return { type: "SYNC_ACTIONS", actions: actions };
}

// Returns undefined if there is no user state, or the user is not signed in.
export function getUserState(topLevelState) {
  return (
    topLevelState.auth.signedIn &&
    topLevelState.auth.currentAccount &&
    topLevelState[topLevelState.auth.currentAccount]
  );
}

// Returns undefined if there is no current list, for exmaple if the user is
// not signed in.
export function getCurrentListState(topLevelState) {
  const userState = getUserState(topLevelState);
  const currentListId = userState && userState.currentList;
  return currentListId && userState[currentListId];
}

export function getListState(topLevelState, listId) {
  const userState = getUserState(topLevelState);
  return userState[listId];
}

let unsubscribe = null;

function observeChangesForUser(user) {
  const query = firebase.firestore().collection(user);

  return query.onSnapshot(function (snapshot) {
    snapshot.docChanges().forEach(function (change) {
      switch (change.doc.data().action) {
        case "UNSHARE_LIST_SUCCESS":
        case "DELETE_LIST_SUCCESS": {
          const listId = change.doc.id;
          const list = getListState(store.getState(), listId);
          if (list) {
            const account = getUserState(store.getState());
            account &&
              store.dispatch(
                syncListsPendingAction(
                  makeListObjectsFromCurrentAccount(account)
                )
              );
          }
          break;
        }
        case "EDIT_TODO_FIELD_SUCCESS": // update: list 'cause it's easier
        case "CREATE_TODO_SUCCESS": // update: list
        case "MOVE_TODO_ITEM_SUCCESS": {
          // update: list
          const listId = change.doc.id;
          const state = getUserState(store.getState());
          if (!state) return;
          const list = getListState(store.getState(), listId);
          if (!list) return;
          const todoId = change.doc.data().todo_id;
          const item = list.todos[todoId];

          let equal = false;
          if (item) {
            const itemTimes = { ...item.extendedProperties.shared.timestamps };
            const dbTimes = { ...change.doc.data().timestamps };
            delete itemTimes.pending;
            delete dbTimes.pending;
            if (change.doc.data().action !== "MOVE_TODO_ITEM_SUCCESS") {
              delete itemTimes.next;
              delete itemTimes.prev;
              delete dbTimes.next;
              delete dbTimes.prev;
            }
            equal = deepEqual(itemTimes, dbTimes);
          }
          if (!equal) {
            store.dispatch(syncTodosPendingAction(listId, [], Date.now()));
          }
          break;
        }
        case "REORDER_LISTS": {
          const userState = getUserState(store.getState());
          if (!userState) return;

          const order = change.doc.data().order;
          const allLists = userState.AllLists;
          const equal = deepEqual(order, allLists);
          if (!equal && allLists.length === order.length) {
            const sortedLocal = [...allLists].sort();
            const sortedRemote = [...order].sort();
            if (deepEqual(sortedLocal, sortedRemote)) {
              store.dispatch(resetListOfListsOrderAction(order));
            }
          }
          break;
        }
        default:
          console.error("Default case ignored: ", change.doc.data());
          break;
      }
    });
  });
}

function writeListOfListsOrder(user, order, action) {
  firebase
    .firestore()
    .collection(user)
    .doc("LIST_ORDER")
    .set({ action: action.type, order })
    .then((ok) => {
      // console.log(
      // "Update for " + action.type + " written to firestore for " + user
      // );
    })
    // TODO: surface this error state?
    .catch((err) => {
      console.error(
        "Update for " + action.type + " failed for " + user + ": " + err,
        err
      );
    });
}
function writeListOfListsUpdate(user, action) {
  firebase
    .firestore()
    .collection(user)
    .doc(action.id)
    .set({ action: action.type })
    .then((ok) => {
      // console.log(
      // "Update for " + action.type + " written to firestore for " + user
      // );
    })
    // TODO: surface this error state?
    .catch((err) => {
      console.error(
        "Update for " + action.type + " failed for " + user + ": " + err,
        err
      );
    });
}
function writeRefreshListUpdate(user, action, timestamps) {
  firebase
    .firestore()
    .collection(user)
    .doc(action.list_id)
    .set({ action: action.type, timestamps, todo_id: action.id })
    .then((ok) => {
      // console.log(
      // "Update for " + action.type + " written to firestore for " + user
      // );
    })
    // TODO: surface this error state?
    .catch((err) => {
      console.error(
        "Update for " + action.type + " failed for " + user + ": " + err,
        err
      );
    });
}
export function updateFirestore(state, action) {
  switch (action.type) {
    case "SIGN_IN":
      if (unsubscribe) {
        unsubscribe();
      }
      unsubscribe = observeChangesForUser(state.auth.currentAccount);
      break;
    case "UNSHARE_LIST_SUCCESS": // update: list of lists
      writeListOfListsUpdate(action.email, action);
      break;
    case "DELETE_LIST_SUCCESS": // update: list of lists
      action.shared.forEach((user) => writeListOfListsUpdate(user, action));
      break;

    case "EDIT_TODO_FIELD_SUCCESS": // update: list 'cause it's easier
    case "CREATE_TODO_SUCCESS": // update: list
    case "MOVE_TODO_ITEM_SUCCESS": {
      // update: list
      const list = getListState(state, action.list_id);
      const shared = list.shared;
      const item = list.todos[action.id];
      const timestamps = item.extendedProperties.shared.timestamps;
      shared.forEach((user) =>
        writeRefreshListUpdate(user, action, timestamps)
      );
      break;
    }

    // TODO: add a notification for share
    // case "SHARE_LIST_PENDING":  // notify, with instructions and url
    default:
      break;
  }
  return state;
}

//export const allReducers = combineReducers({ auth, gapi_state });
//export const allReducers = combineReducers({ auth: auth, gapi_state: gapi_state });
export function allReducers(state = undefined, action) {
  try {
    if (state === undefined) {
      try {
        state = JSON.parse(localStorage.getItem("state"));
        if (state === null) {
          state = {};
        } else {
          delete state.gapi_state;
        }
      } catch (err) {
        state = {};
      }
    }
    var originalStateString = JSON.stringify(state);
    var nextState = {
      ...state,
      auth: auth(state.auth, action),
      gapi_state: gapi_state(state.gapi_state, action),
      ui: ui(state.ui, action),
    };
    const account = nextState.auth && nextState.auth.currentAccount;
    if (account) {
      nextState[account] = user_lists(nextState[account], action);
      notify_on_user_lists(account, nextState[account], action);

      const currentListId = action.list_id;
      // user_todos only works on actions which specify which list_id, so
      // no need to call it if this action doesn't have a list_id.
      if (currentListId) {
        nextState[account] = { ...nextState[account] };
        let substate = nextState[account][currentListId];
        nextState[account][currentListId] = user_todos(substate, action);
        substate = nextState[account][currentListId];
        notify_on_user_todos(account, substate, action);
      }

      if (nextState[account].pendingActions) {
        nextState[account] = { ...nextState[account] };
        nextState.pendingActions = nextState[account].pendingActions;
        delete nextState[account].pendingActions;
      }
    } else if (nextState.pendingActions) {
      var lastAccount = state.auth && state.auth.currentAccount;
      if (lastAccount) {
        // we've signed out; stash unfinished actions in this account's
        // slice.
        nextState[lastAccount] = { ...nextState[lastAccount] };
        nextState[lastAccount].pendingActions = nextState.pendingActions;
        nextState.pendingActions = [];
      }
    }

    if (action.offline) {
      const old = state.pendingActions || [];
      nextState.pendingActions = [...old, action];
    }
    if (action.type === "SYNC_ACTIONS") {
      nextState.pendingActions = action.actions;
    }
    if (action.type === "CREATE_LIST_SUCCESS") {
      // rewrite pending action IDs in the action queue
      var oldId = action.id;
      var newId = action.server_id;
      nextState.pendingActions = nextState.pendingActions.map((action) => {
        var ret = action;
        if (action.id === oldId) {
          ret = { ...ret, id: newId };
        }

        if (action.list_id === oldId) {
          ret = { ...ret, list_id: newId };
        }
        return ret;
      });
    }
    nextState = updateFirestore(nextState, action);
    var checkOriginalStateString = JSON.stringify(state);
    if (originalStateString !== checkOriginalStateString) {
      console.log(
        "ERROR: original state changed. original",
        JSON.parse(originalStateString),
        " new ",
        state
      );
    }
    return nextState;
  } catch (err) {
    console.error("Exception seen while processing action", action);
    throw err;
  }
}

const middlewares = [];
if (process.env.NODE_ENV === "development_logging") {
  const logger = createLogger({
    duration: "true",
    logErrors: false,
    diff: true,
  });
  middlewares.push(logger);
}
middlewares.push(thunkMiddleware);
const middleware = applyMiddleware(...middlewares);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(allReducers, composeEnhancers(middleware));

var stateMap = {};

export function subscribeTo(slice, callback) {
  // don't prepopulate pendingActions, so that if you
  // start the app with pending actions it will immediately
  // try to sync.
  if (slice !== "pendingActions") {
    stateMap[callback] = store.getState()[slice];
  }
  store.subscribe(() => {
    var nextState = store.getState()[slice];
    if (stateMap[callback] && stateMap[callback] === nextState) return;
    stateMap[callback] = nextState;
    callback(nextState);
  });
}
