import React from "react";
import { Component, Fragment } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import HilightOffIcon from "@material-ui/icons/HighlightOff";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PostAddIcon from "@material-ui/icons/PostAdd";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SearchIcon from "@material-ui/icons/Search";
import SyncIcon from "@material-ui/icons/Sync";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import ReplayIcon from "@material-ui/icons/Replay";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import preval from "preval.macro";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as styles from "./styles.module.css";
import "./TodoApp.css";
import * as auth from "./auth";
import * as reducers from "./reducers";
import { sync, syncAll } from "./sync.js";
import {
  formatSummary,
  labelSummary,
  uuid,
  makeListObjectsFromCurrentAccount,
} from "./lists";

import StarredIcon from "./images/star.svg";
import AllIcon from "./images/all.svg";
import ListIcon from "./images/list.svg";
import SharedIcon from "./images/shared.svg";

function OneLineCaption(props) {
  return (
    <Typography variant="caption" display="block">
      {props.children}
    </Typography>
  );
}

function BuildInfo(props) {
  return (
    <OneLineCaption>
      {preval`module.exports = new Date().toLocaleString();`}
    </OneLineCaption>
  );
}

function AppInfoPanel(props) {
  return (
    <Fragment>
      {props.auth.signedIn && props.pendingActions && (
        <Paper elevation={0} style={{ paddingLeft: "1em" }}>
          <Typography
            variant="caption"
            style={{ display: "flex", alignItems: "center" }}
          >
            {props.pendingActions.length === 0 ? (
              <Fragment>
                <OfflinePinIcon color="disabled" /> synced
              </Fragment>
            ) : (
              <Fragment>
                <SyncIcon color="disabled" /> not synced:{" "}
                {props.pendingActions.length}
              </Fragment>
            )}
          </Typography>
        </Paper>
      )}
      <Paper elevation={0} style={{ paddingLeft: "1em" }}>
        <OneLineCaption>{props.auth.currentAccount}</OneLineCaption>
        <OneLineCaption>{props.auth.authMessage}</OneLineCaption>
        <BuildInfo />
      </Paper>
    </Fragment>
  );
}

class Auth extends Component {
  render() {
    return (
      <Fragment>
        <Toolbar>
          {this.props.signedIn === false && (
            <Button color="inherit" onClick={auth.signInButtonPressed}>
              Sign In
            </Button>
          )}
          {this.props.signedIn === true && (
            <Button color="inherit" onClick={this.props.signOutAsync}>
              Sign Out
            </Button>
          )}
          {this.props.signedIn === undefined && <CircularProgress />}
        </Toolbar>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state.auth;
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOutAsync: (e) => dispatch(auth.signOutAsync),
  };
};

const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth);

const REFRESH = "↺";

// Returns the refresh symbol or an error message, or the empty string if the
// item is not pending.
function pendingStateAsText(pendingList, id) {
  return pendingList[id] === true
    ? REFRESH
    : pendingList[id]
    ? pendingList[id]
    : "";
}

const SortableListName = SortableElement(({ props, listId }) => {
  return (
    <div style={{ zIndex: 100000 }}>
      <ListItem
        button
        key={listId}
        selected={listId === props.currentList}
        onClick={() => props.selectList(listId)}
      >
        {props[listId].shared && props[listId].shared.length > 1 ? (
          <img src={SharedIcon} alt="list" />
        ) : (
          <img src={ListIcon} alt="list" />
        )}
        <ListItemText
          primary={
            formatSummary(props[listId].listMetadata.summary) +
            pendingStateAsText(props.PendingLists, listId)
          }
        />
        {listId === props.currentList && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={props.showEditListDialog}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </div>
  );
});

class ListOfLists extends Component {
  render() {
    let i = 0;
    return (
      <List component="nav">
        <ListItem
          button
          key={"∞"}
          selected={"∞" === this.props.currentList}
          onClick={() => this.props.selectList("∞")}
        >
          <img src={AllIcon} alt="all" />
          <ListItemText primary={"All"} />
        </ListItem>
        <ListItem
          button
          key={"★"}
          selected={"★" === this.props.currentList}
          onClick={() => this.props.selectList("★")}
        >
          <img src={StarredIcon} alt="★" />
          <ListItemText primary={"Starred"} />
        </ListItem>
        <ListItem
          button
          key={"Ω"}
          selected={"Ω" === this.props.currentList}
          onClick={() => this.props.selectList("Ω")}
        >
          <DateRangeIcon
            color="disabled"
            style={{ marginLeft: -2, marginRight: 8 }}
          />
          <ListItemText primary={"By Date"} />
        </ListItem>
        <ListItem
          button
          key={"Φ"}
          selected={"Φ" === this.props.currentList}
          onClick={() => this.props.selectList("Φ")}
        >
          <SearchIcon
            color="disabled"
            style={{ marginLeft: -2, marginRight: 8 }}
          />
          <ListItemText primary={"Search"} />
        </ListItem>
        {this.props.AllLists &&
          this.props.AllLists.map((listId) => (
            <SortableListName
              index={i++}
              key={listId}
              props={this.props}
              listId={listId}
            />
          ))}
      </List>
    );
  }
}

const isComposedList = (id) => {
  return id === "★" || id === "∞" || id === "Ω" || id === "Φ";
};

const mapListOfListsStateToProps = (state) => {
  return state[state.auth.currentAccount] || {};
};

const mapListOfListsDispatchToProps = (dispatch) => {
  return {
    selectList: (listId) => {
      dispatch((dispatch, getState) => {
        if (!isComposedList(listId)) {
          dispatch(reducers.syncTodosPendingAction(listId, []));
        } else {
          const userState = reducers.getUserState(getState());
          if (userState) {
            userState.AllLists.forEach((id) =>
              dispatch(reducers.syncTodosPendingAction(id, []))
            );
          }
        }
        dispatch(reducers.selectListAction(listId));
      });
    },
    showEditListDialog: () => dispatch(reducers.showEditListDialogAction()),
  };
};

const ListOfListsContainer = SortableContainer(
  connect(
    mapListOfListsStateToProps,
    mapListOfListsDispatchToProps
  )(ListOfLists)
);

const SortableItem = SortableElement(({ task, container }) => (
  <TaskDisplay listId={container.props.listId} task={task} />
));

const SortableList = SortableContainer(({ items, container }) => {
  return (
    <List dense>
      <TransitionGroup id="fade">
        {items.map((value, index) => (
          <CSSTransition key={value[0]} classNames="fade" timeout={300}>
            <SortableItem
              key={value[0]}
              index={index}
              task={value}
              container={container}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </List>
  );
});

class EditableTaskComponent extends Component {
  input = React.createRef();

  onSubmit = (e) => {
    e.preventDefault();
    document.activeElement.blur();
  };
  render() {
    if (this.input.current) {
      this.input.current.value = this.props.task.description;
    }
    return (
      <Fragment>
        <form className="MuiInputBase-fullWidth" onSubmit={this.onSubmit}>
          <InputBase
            fullWidth
            className={this.props.className}
            defaultValue={this.props.task.description}
            inputRef={this.input}
            onBlur={(e) => {
              this.props.finalizeField(
                this.input,
                this.props.listId,
                this.props.taskId,
                this.props.task.description
              );
              window.setTimeout(
                () => this.props.clearCurrentTask(this.props.taskId),
                300
              );
            }}
            onFocus={() => this.props.setCurrentTask(this.props.taskId)}
          />
        </form>
      </Fragment>
    );
  }
}

EditableTaskComponent.propTypes = {
  task: PropTypes.object.isRequired,
};

const mapEditableTaskComponentStateToProps = (state, ownProps) => {
  const userState = reducers.getUserState(state);
  return userState[ownProps.listId] || {};
};

const mapEditableTaskComponentDispatchToProps = (dispatch) => {
  return {
    finalizeField: (e, listId, id, originalText) => {
      const newText = e.current.value;
      if (newText !== originalText) {
        dispatch(
          reducers.editTodoFieldsPendingAction(
            listId,
            id,
            [{ field: "description", value: newText }],
            Date.now()
          )
        );
      }
    },
    clearCurrentTask: (taskId) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        if (
          !state.ui.taskDetailsAreShowing &&
          taskId === state.ui.currentTask
        ) {
          dispatch(reducers.setCurrentTaskAction(undefined));
        }
      });
    },
    setCurrentTask: (taskId) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        if (!state.ui.taskDetailsAreShowing || taskId) {
          dispatch(reducers.setCurrentTaskAction(taskId));
        }
      });
    },
  };
};
const EditableTaskContainer = connect(
  mapEditableTaskComponentStateToProps,
  mapEditableTaskComponentDispatchToProps
)(EditableTaskComponent);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const daysFull = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const ordinals = ["zeroth", "first", "second", "third", "fourth", "fifth"];

function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function isToday(d) {
  return isSameDay(d, new Date());
}

function isTommorow(d) {
  const other = new Date();
  other.setDate(other.getDate() + 1);
  return isSameDay(d, other);
}

function isYesterday(d) {
  const other = new Date();
  other.setDate(other.getDate() - 1);
  return isSameDay(d, other);
}

function isOverdue(date) {
  const now = new Date();
  return date.getTime() < now.getTime() && !isToday(date);
}

function formatDueDate(date) {
  const now = new Date();

  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isTommorow(date)) {
    return "Tomorrow";
  }
  const y = date.getFullYear();
  const m = months[date.getMonth()];
  const d = date.getDate();
  const dayName = days[date.getDay()];
  return (
    dayName + ", " + m + " " + d + (now.getFullYear() !== y ? ", " + y : "")
  );
}

function CompactTextDisplay(props) {
  const classes = classNames(props.className, styles.compactText);
  return (
    <span onClick={props?.onClick} className={classes}>
      {props.text}
    </span>
  );
}

const makeDateFromTaskDueDate = (task) =>
  (task.extendedProperties.shared.dueDate &&
    new Date(task.extendedProperties.shared.dueDate + "T00:00:00")) ||
  undefined;

class TaskDisplayComponent extends Component {
  sound = new Audio("audio/completed.mp3");

  makeNextRecurringDueDate(dueDate, repeats, repeatInterval) {
    const now = new Date();
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );
    let nextDate = new Date(dueDate.getTime());
    while (
      nextDate.getTime() <= dueDate.getTime() ||
      nextDate.getTime() <= today.getTime()
    ) {
      switch (repeats) {
        case "daily":
          nextDate.setDate(nextDate.getDate() + repeatInterval);
          break;
        case "weekly":
          nextDate.setDate(nextDate.getDate() + 7 * repeatInterval);
          break;
        case "monthly":
          nextDate = new Date(
            nextDate.getFullYear(),
            nextDate.getMonth() + repeatInterval,
            nextDate.getDate()
          );
          break;
        case "yearly":
          nextDate = new Date(
            nextDate.getFullYear() + repeatInterval,
            nextDate.getMonth(),
            nextDate.getDate()
          );
          break;
        case "weekdays":
          nextDate.setDate(nextDate.getDate() + 1);
          while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
            nextDate.setDate(nextDate.getDate() + 1);
          }
          break;
        default:
          // Error.
          return nextDate;
      }
    }
    return nextDate;
  }

  render() {
    const taskDetails = this.props.task;
    const taskId = taskDetails[0];
    const task = taskDetails[1];
    const pendingState = taskDetails[2];
    const completed = task.extendedProperties.shared.done === true;
    const starred = task.extendedProperties.shared.starred === true;
    const repeats =
      task.extendedProperties.shared.repeats !== "noRepeat"
        ? task.extendedProperties.shared.repeats
        : undefined;
    let repeatInterval = parseInt(task.extendedProperties.shared.repeatInterval);
		if (isNaN(repeatInterval)) {
			repeatInterval = 1;
		}

    const { classes, className, listId } = this.props;
    const pending = task.extendedProperties.shared.pending || {};
    let dueDate = new Date();
    dueDate = new Date(
      dueDate.getFullYear(),
      dueDate.getMonth(),
      dueDate.getDate()
    );
    if (task.extendedProperties.shared.dueDate) {
      dueDate = makeDateFromTaskDueDate(task);
    }

    return (
      <Paper className={classNames(classes.itemMargins)}>
        <ListItem className={classNames(classes.itemPadding)} key={taskId}>
          <Checkbox
            className={classNames(pending.done && classes.pending, className)}
            checked={completed}
            color="primary"
            tabIndex={-1}
            onClick={() => {
              repeats
                ? this.props.setNextDueDate(
                    listId,
                    taskId,
                    this.makeNextRecurringDueDate(
                      dueDate,
                      repeats,
                      repeatInterval
                    ),
                    this.sound
                  )
                : this.props.setDoneTo(
                    listId,
                    taskId,
                    !completed,
                    !completed && this.sound
                  );
            }}
          />
          <EditableTaskContainer
            className={classNames(
              pending.description && classes.pending,
              className
            )}
            task={task}
            listId={listId}
            taskId={taskId}
          />
          {task.extendedProperties.shared.dueDate && (
            <span>
              <span
                className={classNames({
                  [styles.compactText]: repeats,
                })}
              >
                <CompactTextDisplay
                  text={formatDueDate(dueDate)}
                  className={classNames({
                    [styles.overdue]: isOverdue(dueDate),
                  })}
                  onClick={() => this.props.showTaskDetails(listId, taskId)}
                />
                {repeats && (
                  <ReplayIcon
                    style={{ fontSize: "10pt" }}
                    onClick={() => this.props.showTaskDetails(listId, taskId)}
                  />
                )}
              </span>
              {this.props.showListName && (
                <Fragment>
                  <CompactTextDisplay
                    text={formatSummary(this.props.listMetadata.summary)}
                  />
                </Fragment>
              )}
            </span>
          )}
          {taskId === this.props.ui.currentTask && (
            <IconButton
              edge="end"
              aria-label="Edit details"
              onClick={() => this.props.showTaskDetails(listId, taskId)}
            >
              <PostAddIcon />
            </IconButton>
          )}
          {this.props.ui.syncDelay > 0 && (
            <Fragment>
              {pendingState}
              {pending.prev && "↑"}
              {pending.next && "↓"}
              {pending.done && "d"}
              {pending.description && "t"}
              {pending.starred && "s"}
            </Fragment>
          )}
          <Checkbox
            checked={starred}
            color="primary"
            tabIndex={-1}
            onClick={() =>
              this.props.setStarredTo(
                listId,
                taskId,
                !starred,
                this.props.anchor_id
              )
            }
            checkedIcon={
              <Icon
                className={classNames(
                  pending.starred && classes.pending,
                  classes.starred,
                  className
                )}
              >
                ★
              </Icon>
            }
            icon={
              <Icon
                className={classNames(
                  pending.starred && classes.pending,
                  classes.unstarred,
                  className
                )}
              >
                ☆
              </Icon>
            }
          />
          {taskId && taskId === this.props.ui.taskDetailsAreShowing?.taskId && (
            <TaskDetails
              open={this.props.ui.taskDetailsAreShowing !== null}
              listId={this.props.ui.taskDetailsAreShowing.listId}
              taskId={this.props.ui.taskDetailsAreShowing.taskId}
              onDone={this.props.hideTaskDetails}
              dueDate={dueDate}
            />
          )}
        </ListItem>
      </Paper>
    );
  }
}

TaskDisplayComponent.propTypes = {
  task: PropTypes.array.isRequired,
  showListName: PropTypes.bool,
};

const mapTaskDisplayStateToProps = (state, ownProps) => {
  const userState = reducers.getUserState(state);
  const currentListId = ownProps.listId;
  return currentListId
    ? {
        ...userState[currentListId],
        ui: state.ui,
      }
    : {};
};

const mapTaskDisplayDispatchToProps = (dispatch) => {
  return {
    setDoneTo: (listId, id, value, doneSound) => {
      if (doneSound) {
        doneSound.play();
      }
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [{ field: "done", value: value }],
          Date.now()
        )
      );
    },
    setNextDueDate: (listId, id, nextDueDate, doneSound) => {
      if (doneSound) {
        doneSound.play();
      }
      let strDate = nextDueDate.toISOString();
      strDate = strDate.substring(0, strDate.indexOf("T"));
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [{ field: "dueDate", value: strDate }],
          Date.now()
        )
      );
    },
    setStarredTo: (listId, id, value, anchor) => {
      const now = Date.now();
      if (value === true) {
        dispatch(reducers.moveTodoItemPending(listId, id, anchor, now));
      }
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [{ field: "starred", value: value }],
          now
        )
      );
    },
    showTaskDetails: (listId, id) =>
      dispatch(reducers.showTaskDetailsAction(listId, id)),
    hideTaskDetails: () => {
      dispatch(reducers.setCurrentTaskAction(undefined));
      dispatch(reducers.hideTaskDetailsAction());
    },
  };
};
const TaskDisplayContainer = connect(
  mapTaskDisplayStateToProps,
  mapTaskDisplayDispatchToProps
)(TaskDisplayComponent);

const taskDisplayStyles = {
  starred: {
    width: "1.0em",
    lineHeight: "1.2em",
    height: "28px",
    fontFamily: "serif",
    color: "#ffb74d",
  },
  unstarred: {
    width: "1.0em",
    lineHeight: "1.2em",
    height: "28px",
    fontFamily: "serif",
    color: "lightgrey",
  },
  pending: {
    opacity: "0.5",
    //border: '1px solid red',
  },
  itemPadding: {
    padding: "0px",
  },
  itemMargins: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "1px",
    marginBottom: "1px",
  },
};
const TaskDisplay = withStyles(taskDisplayStyles)(TaskDisplayContainer);

class TaskDetailsComponent extends Component {
  input = React.createRef();

  constructor(props) {
    super(props);
    const { taskId } = this.props;
    const task = this.props.todos && this.props.todos[taskId];
    this.state = {
      useDueDate: task && task.extendedProperties.shared.dueDate ? true : false,
      date: this.props.dueDate,
      repeats: (task && task.extendedProperties.shared.repeats) || "noRepeat",
      repeatInterval:
        (task && task.extendedProperties.shared.repeatInterval) || 1,
    };
  }

  repeatTypeToPeriod = {
    daily: "day",
    weekly: "week",
    monthly: "month",
    yearly: "year",
  };

  convertToOrdinal = (n) => {
    if (n < ordinals.length) {
      return ordinals[n];
    }
    return n + "th";
  };

  render() {
    const { open, listId, taskId, onDone } = this.props;
    const task = this.props.todos && this.props.todos[taskId];
    if (this.input.current) {
      this.input.current.value = task.description;
    }

    return (
      <Dialog
        onClose={onDone}
        aria-labelledby="task-details-title"
        aria-describedby="task-details-description"
        open={open}
      >
        <DialogTitle id="task-details-title">
          {task && (
            <InputBase
              fullWidth
              multiline
              className={this.props.className}
              defaultValue={task.description}
              inputRef={this.input}
              onBlur={(e) =>
                this.props.finalizeField(
                  this.input,
                  listId,
                  taskId,
                  task.description
                )
              }
            />
          )}
        </DialogTitle>
        <DialogContent>
          <Checkbox
            checked={this.state.useDueDate}
            color="primary"
            onClick={(e) => {
              let useDueDate = !this.state.useDueDate;
              this.setState({ useDueDate });
              this.props.handleDateChange(
                listId,
                taskId,
                this.state.date,
                useDueDate
              );
              this.props.handleRepeatChange(
                listId,
                taskId,
                this.state.repeats,
                useDueDate
              );
            }}
          />
          <KeyboardDatePicker
            disabled={!this.state.useDueDate}
            variant="inline"
            format="yyyy/MM/dd"
            margin="normal"
            id="task-details-date-picker"
            label="Task due date"
            value={this.state.date}
            onChange={(date) => {
              this.setState({ date });
              if (
                !this.state.useDueDate ||
                // eslint-disable-next-line eqeqeq
                (date !== null && date != "Invalid Date")
              ) {
                this.props.handleDateChange(
                  listId,
                  taskId,
                  date,
                  this.state.useDueDate
                );
              }
            }}
            KeyboardButtonProps={{ "aria-label": "change date" }}
          />
          <br />
          <Checkbox style={{ visibility: "hidden" }} />
          <FormControl disabled={!this.state.useDueDate}>
            <InputLabel id="select-repeat-type-label">Repeat</InputLabel>
            <Select
              labelId="select-repeat-type-label"
              id="select-repeat-type"
              value={this.state.repeats}
              onChange={(e) => {
                this.setState({ repeats: e.target.value });
                this.props.handleRepeatChange(
                  listId,
                  taskId,
                  e.target.value,
                  this.state.useDueDate
                );
								this.props.handleRepeatIntervalChange(
									listId,
									taskId,
									this.state.repeatInterval,
									this.state.useDueDate
								);
              }}
            >
              <MenuItem value={"noRepeat"}>Doesn&apos;t repeat</MenuItem>
              <MenuItem value={"daily"}>Daily</MenuItem>
              <MenuItem value={"weekly"}>
                Weekly on {daysFull[this.state.date.getDay()]}
              </MenuItem>
              <MenuItem value={"monthly"}>
                Monthly on day {this.state.date.getDate()}
              </MenuItem>
              <MenuItem value={"yearly"}>
                Yearly on {months[this.state.date.getMonth()]}{" "}
                {this.state.date.getDate()}
              </MenuItem>
              <MenuItem value={"weekdays"}>Every Weekday (Mon to Fri)</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            {this.state.repeats !== "weekdays" &&
              this.state.repeats !== "noRepeat" && (
                <Paper
                  elevation={0}
                  style={{
                    paddingTop: "1em",
                    display: "flex",
                    alignItems: "center",
                    width: "12em",
                    paddingLeft: "0.5em",
                  }}
                >
                  {" every "}
                  <TextField
                    disabled={!this.state.useDueDate}
                    type="number"
                    value={this.state.repeatInterval}
                    style={{
                      width: "4em",
                      paddingLeft: "0.5em",
                      paddingRight: "0.5em",
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: { textAlign: "center" },
                      },
                    }}
                    onChange={(e) => {
                      let val = parseInt(e.target.value);
                      this.setState({ repeatInterval: val });
                      this.props.handleRepeatIntervalChange(
                        listId,
                        taskId,
                        val,
                        this.state.useDueDate
                      );
                    }}
                  ></TextField>
                  {this.repeatTypeToPeriod[this.state.repeats]}
                  {this.state.repeatInterval === 1 ? "" : "s"}
                </Paper>
              )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDone} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TaskDetailsComponent.propTypes = {
  open: PropTypes.bool,
  listId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
};

const mapTaskDetailsStateToProps = (state, ownProps) => {
  return reducers.getListState(state, ownProps.listId) || {};
};

const mapTaskDetailsDispatchToProps = (dispatch) => {
  return {
    handleDateChange: (listId, id, date, useDueDate) => {
      let strDate = undefined;
      if (useDueDate) {
        strDate = date.toISOString();
        strDate = strDate.substring(0, strDate.indexOf("T"));
      }
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [{ field: "dueDate", value: strDate }],
          Date.now()
        )
      );
    },
    handleRepeatChange: (listId, id, repeats, enable) => {
      let repeatsVal = enable && repeats !== "noRepeat" ? repeats : undefined;
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [{ field: "repeats", value: repeatsVal }],
          Date.now()
        )
      );
    },
    handleRepeatIntervalChange: (listId, id, repeatInterval, enable) => {
      dispatch(
        reducers.editTodoFieldsPendingAction(
          listId,
          id,
          [
            {
              field: "repeatInterval",
              value: enable ? repeatInterval : undefined,
            },
          ],
          Date.now()
        )
      );
    },
    finalizeField: (e, listId, id, originalText) => {
      const newText = e.current.value;
      if (newText !== originalText) {
        dispatch(
          reducers.editTodoFieldsPendingAction(
            listId,
            id,
            [{ field: "description", value: newText }],
            Date.now()
          )
        );
      }
    },
  };
};

const taskDetailsStyles = {};

const TaskDetails = connect(
  mapTaskDetailsStateToProps,
  mapTaskDetailsDispatchToProps
)(withStyles(taskDetailsStyles)(TaskDetailsComponent));

class TodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFiltered: this.props.filterName ? true : false,
      filterName: this.props.filterName || "Completed Tasks",
    };
  }
  render() {
    var taskList = [];
    var filteredTasks = [];
    var head = this.props.anchor_id;
    var current = head;
    while (
      head &&
      this.props.todos &&
      reducers.getNext(this.props.todos, current) !== head
    ) {
      current = reducers.getNext(this.props.todos, current);
      if (!this.props.filterTask) {
        if (this.props.todos[current].extendedProperties.shared.done !== true) {
          taskList.push([
            current,
            this.props.todos[current],
            pendingStateAsText(this.props.pendingTodos, current),
          ]);
        } else {
          filteredTasks.push([
            current,
            this.props.todos[current],
            pendingStateAsText(this.props.pendingTodos, current),
          ]);
        }
      } else if (this.props.filterTask(this.props.todos[current])) {
        filteredTasks.push([
          current,
          this.props.todos[current],
          pendingStateAsText(this.props.pendingTodos, current),
        ]);
      }
    }

    return (
      <Fragment>
        {!this.props.filterTask && (
          <Fragment>
            <EnterText
              whiteField={true}
              cueText="+ New task"
              enterPressed={(text) =>
                this.props.createTodo(this.props.listId, text)
              }
            />
            <Fragment>
              <SortableList
                pressDelay={200}
                items={taskList}
                container={this}
                onSortEnd={(indices) =>
                  this.props.handleDragComplete(
                    this.props.listId,
                    taskList,
                    indices
                  )
                }
              />
            </Fragment>
          </Fragment>
        )}
        {filteredTasks.length > 0 &&
          (!this.state.showFiltered ? (
            <Button
              fullWidth
              color="primary"
              onClick={() => this.setState({ showFiltered: true })}
            >
              Show {this.state.filterName}
            </Button>
          ) : (
            <React.Fragment>
              <Button
                fullWidth
                color="primary"
                onClick={() => this.setState({ showFiltered: false })}
              >
                Hide {this.state.filterName}
              </Button>
              <SortableList
                pressDelay={200}
                items={filteredTasks}
                container={this}
                onSortEnd={(indices) =>
                  this.props.handleDragComplete(
                    this.props.listId,
                    filteredTasks,
                    indices
                  )
                }
              />
            </React.Fragment>
          ))}
      </Fragment>
    );
  }
}

TodoList.propTypes = {
  listId: PropTypes.string.isRequired,
  listMetadata: PropTypes.object.isRequired,
};

const mapTodoListStateToProps = (state, ownProps) => {
  const userState = reducers.getUserState(state);
  return userState[ownProps.listId] || {};
};

const mapTodoListDispatchToProps = (dispatch) => {
  return {
    createTodo: (listId, description) =>
      dispatch(
        reducers.createPendingTodoAction(
          uuid(),
          listId,
          description,
          Date.now()
        )
      ),
    handleDragComplete: (listId, list, indices) => {
      var oldIndex = indices.oldIndex;
      var newIndex = indices.newIndex;
      if (oldIndex === newIndex) return;
      var before = list[newIndex];
      var moveMe = list[oldIndex];
      if (before[0] === moveMe[0]) return;
      var prev = before[0];
      if (newIndex < oldIndex) {
        prev = before[1].extendedProperties.shared.prev;
      }
      dispatch(
        reducers.moveTodoItemPending(listId, moveMe[0], prev, Date.now())
      );
    },
  };
};

const TodoListContainer = connect(
  mapTodoListStateToProps,
  mapTodoListDispatchToProps
)(TodoList);

class TodoDateListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFiltered: true,
      filterName: this.props.filterName || "Tasks by Date",
    };
  }

  getDatedTasks(listId, listState) {
    let taskList = [];
    let head = listState.anchor_id;
    let current = head;
    while (
      head &&
      listState.todos &&
      reducers.getNext(listState.todos, current) !== head
    ) {
      current = reducers.getNext(listState.todos, current);
      let task = listState.todos[current];

      if (
        task.extendedProperties.shared.dueDate &&
        task.extendedProperties.shared.done !== true
      ) {
        taskList.push([
          current,
          task,
          pendingStateAsText(listState.pendingTodos, current),
          listId,
        ]);
      }
    }
    return taskList;
  }

  render() {
    let lists = this.props.lists || [];
    let userState = this.props;

    let taskList = [];
    lists.forEach((listId) => {
      taskList = [
        ...taskList,
        ...this.getDatedTasks(listId, userState[listId]),
      ];
    });

    taskList.sort(
      (a, b) =>
        makeDateFromTaskDueDate(a[1]).getTime() -
        makeDateFromTaskDueDate(b[1]).getTime()
    );

    return (
      <Fragment>
        {taskList.length > 0 &&
          (!this.state.showFiltered ? (
            <Button
              fullWidth
              color="primary"
              onClick={() => this.setState({ showFiltered: true })}
            >
              Show {this.state.filterName}
            </Button>
          ) : (
            <Fragment>
              <Button
                fullWidth
                color="primary"
                onClick={() => this.setState({ showFiltered: false })}
              >
                Hide {this.state.filterName}
              </Button>

              <List dense>
                <TransitionGroup id="fade">
                  {taskList.map((task) => (
                    <CSSTransition
                      key={task[0]}
                      classNames="fade"
                      timeout={300}
                    >
                      <TaskDisplay
                        key={task[0]}
                        task={task}
                        listId={task[3]}
                        showListName
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </List>
            </Fragment>
          ))}
      </Fragment>
    );
  }
}

TodoDateListComponent.propTypes = {
  lists: PropTypes.array.isRequired,
};

const mapTodoDateListStateToProps = (state, ownProps) => {
  return reducers.getUserState(state);
};

const TodoDateList = connect(mapTodoDateListStateToProps)(
  TodoDateListComponent
);

const whiteFieldStyles = makeStyles({
  root: {
    "& label": {
      color: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "white",
    },
  },
});

function ColoredTextField(props) {
  const whiteClasses = whiteFieldStyles(); // This must not be in any conditional.
  const classes = (props.whiteField && whiteClasses) || {};
  const subProps = { ...props };
  delete subProps.whiteField;
  return <TextField classes={classes} {...subProps} />;
}

class EnterTextComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      // 'Enter' key
      const text = e.target.value;
      if (text.length > 0) {
        this.setState({ name: "" });
        this.props.enterPressed(text);
      }
    }
  };

  render() {
    const { whiteField, classes } = this.props;
    return (
      <Paper className={whiteField ? classNames(classes.enterText) : ""}>
        <ColoredTextField
          whiteField={whiteField}
          variant="filled"
          fullWidth
          label={this.props.cueText}
          value={this.state.name}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
      </Paper>
    );
  }
}

const enterTextStyles = {
  enterText: {
    backgroundColor: "rgba(115,62,39,0.65)",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "10px",
    marginBottom: "10px",
  },
};
const EnterText = withStyles(enterTextStyles)(EnterTextComponent);

class OnEditTextFieldComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.defaultValue || "",
    };
  }

  handleChange = (e) => {
    this.setState({ name: e.target.value });
    this.props.onTextChange && this.props.onTextChange(e.target.value);
  };

  render() {
    const { whiteField, classes, startAdornment } = this.props;
    return (
      <Paper className={whiteField ? classNames(classes.enterText) : ""}>
        <ColoredTextField
          whiteField={whiteField}
          variant="filled"
          fullWidth
          label={this.props.cueText}
          value={this.state.name}
          onChange={this.handleChange}
          InputProps={{
            startAdornment,
          }}
        />
      </Paper>
    );
  }
}

const OnEditTextField = withStyles(enterTextStyles)(OnEditTextFieldComponent);

const todoAppStyles = (theme) => ({
  toolbar: {
    backgroundColor: "#733E39",
    color: "white",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  root: {
    display: "flex",
    height: "100vh",
  },
  nav: {
    width: "280px",
    height: "100vh",
    flexShrink: "0",
    overflow: "scroll",
  },
  content: {
    flexGrow: "1",
    overflow: "scroll",
  },
});

export class TodoApp extends Component {
  /*
	constructor(props) {
		super(props);
		// the state should look something like this:
		// {
		//   gapi_state: { isOnline: true, isLoading: false, message: undefined }
		//   auth: { currentAccount: email@foo.com, signedIn: true }
		//   "email@foo.com": {
		//      AllLists: [ "id1", "id2", "id3" ],
		//      AllListsSyncToken: 1234,
		//      PendingLists: { "id1": true, "id2": "error message" } // undefined for sync'ed lists
		//      currentList: "id2",
		//      "id1": {
		//        listMetadata {
		//          summary: "name of calendar",
		//        }
		//        creator: email,
		//        shared: [email1, email2,],
		//        anchor_id: "item0",
		//        todos: {
		//          item0: {
		//            description: "⚓",
		//            extendedProperties: { shared: {
		//              done: false, starred: false,
		//              next: "item1", prev: "item2",
		//              dueDate: undefined | date,
		//              repeats: undefined | "daily" | "weekly" | "monthly" | "yearly" | "weekdays",
		//              repeatInterval: undefined | positive-integer,
		//              timestamps: { description: 1234, done: 5555, starred: 4444, next: 9999, prev: 8888,
    //                           dueDate: 9999, repeats: 4040, repeatInterval: 4040 },
		//            } },
		//          },
		//          item1: {
		//            description: "todo 1",
		//            extendedProperties: { shared: {
		//              done: false, starred: true,
		//              next: "item2", prev: "item0",
		//              dueDate: undefined | date,
		//              repeats: undefined | "daily" | "weekly" | "monthly" | "yearly" | "weekdays",
		//              repeatInterval: undefined | positive-integer,
    //              timestamps: { description: 4321, done: 2121, starred: 3131, next: 1010, prev: 2888,
    //                           dueDate: 1999, repeats: 4040, repeatInterval: 4040 },
		//            } },
		//          },
		//          item2: {
		//            description: "todo 2",
		//            extendedProperties: { shared: {
		//              done: false, starred: false,
		//              next: "item0", prev: "item1",
		//              dueDate: undefined | date,
		//              repeats: undefined | "daily" | "weekly" | "monthly" | "yearly" | "weekdays",
		//              repeatInterval: undefined | positive-integer,
		//              timestamps: { description: 2413, done: 1212, starred: 1331, next: 1919, prev: 4888,
    //                           dueDate: 2999, repeats: 4040, repeatInterval: 4040 },
		//            } },
		//          },
		//        },
		//        pendingTodos: {"item1": true, "item2": "error message" }, // undefined for sync'ed todos
		//      },
		//      "id2": [],
		//      ...
		//   },
		//   "email2@foo.com": {
		//     AllLists: [],
		//     AllListsSyncToken: 5678,
		//   }
		//   ui: {
    //     currentTask: "task_id",
    //     searchTerm: "find me",
		//     drawerIsOpen: false,
		//     editListDialogIsShowing: false,
		//     deleteListDialogIsShowing: false,
		//     taskDetailsIsShowing: false,
		//     syncItemCount: 0,
		//     syncDelay: 1000, // set to 0 for production mode
		//   },
		//   pendingActions: [ ACTION_1, ACTION_2, ... ] // an array of pending state changes that haven't gone to the server
		// }
	}
	*/
  getListName(userState) {
    let id = userState && userState.currentList;
    if (id === "★") {
      return "Starred";
    }
    if (id === "∞") {
      return "All";
    }
    if (id === "Ω") {
      return "By Date";
    }
    if (id === "Φ") {
      return "Search";
    }
    return id && formatSummary(userState[id].listMetadata.summary);
  }

  renderDrawer() {
    const userState = reducers.getUserState(this.props);
    return (
      <Fragment>
        <ListOfListsContainer
          pressDelay={200}
          onSortEnd={this.props.reorderListOfLists}
        />
        {this.props.auth.signedIn && (
          <EnterText
            cueText="+ New list"
            enterPressed={this.props.createNewList}
          />
        )}
        {userState &&
          userState.currentList &&
          userState[userState.currentList] && (
            <Fragment>
              <EditListDialog
                open={this.props.ui.editListDialogIsShowing}
                listId={userState.currentList}
                listName={this.getListName(userState)}
                onCancel={this.props.hideEditListDialog}
              />
              <DeleteListDialog
                open={this.props.ui.deleteListDialogIsShowing}
                listNameToDelete={this.getListName(userState)}
                onDelete={() => {
                  this.props.deleteList(userState.currentList);
                }}
                onCancel={this.props.hideDeleteListDialog}
              />
            </Fragment>
          )}
        <AuthContainer />
        <AppInfoPanel
          auth={this.props.auth}
          pendingActions={this.props.pendingActions}
        />
      </Fragment>
    );
  }

  render() {
    const userState = reducers.getUserState(this.props);
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={this.props.classes.transparent}>
          <CssBaseline />
          <div className={this.props.classes.root}>
            {this.props.auth.signedIn && (
              <Hidden xsDown implementation="css">
                <Paper square className={this.props.classes.nav}>
                  {this.renderDrawer()}
                </Paper>
              </Hidden>
            )}
            <div className={this.props.classes.content}>
              <AppBar position="sticky" className={this.props.classes.toolbar}>
                <Toolbar className={this.props.classes.toolbar}>
                  <Hidden smUp implementation="css">
                    {this.props.auth.signedIn && (
                      <IconButton
                        color="inherit"
                        aria-label="Show todo lists"
                        onClick={this.props.openListDrawer}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                  </Hidden>
                  <Typography variant="h6" color="inherit">
                    {this.getListName(userState) || "Todo List App"}
                  </Typography>
                  {this.props.auth.signedIn && this.props.ui.syncDelay > 0 && (
                    <Fragment>
                      <Button color="inherit" onClick={this.props.sync}>
                        Step ({"" + this.props.ui.syncItemCount})
                      </Button>
                      <Button color="inherit" onClick={this.props.syncAll}>
                        Sync All
                      </Button>
                    </Fragment>
                  )}
                </Toolbar>
              </AppBar>

              <Drawer
                open={this.props.ui.drawerIsOpen}
                onClose={this.props.closeListDrawer}
              >
                {this.renderDrawer()}
              </Drawer>
              {!this.props.auth.signedIn && (
                <Fragment>
                  <AuthContainer />
                  <AppInfoPanel auth={this.props.auth} />
                </Fragment>
              )}
              {userState &&
                userState.currentList &&
                userState.currentList.length !== 1 && (
                  <TodoListContainer
                    key={userState.currentList}
                    listId={userState.currentList}
                  />
                )}
              {userState &&
                userState.currentList === "∞" &&
                userState.AllLists.map((list) => {
                  return (
                    <TodoListContainer
                      key={list}
                      listId={list}
                      filterTask={(x) =>
                        x.extendedProperties.shared.done !== true
                      }
                      filterName={formatSummary(
                        userState[list].listMetadata.summary
                      )}
                    />
                  );
                })}
              {userState &&
                userState.currentList === "★" &&
                userState.AllLists.map((list) => {
                  return (
                    <TodoListContainer
                      key={list}
                      listId={list}
                      filterTask={(x) =>
                        x.extendedProperties.shared.starred === true &&
                        x.extendedProperties.shared.done !== true
                      }
                      filterName={formatSummary(
                        userState[list].listMetadata.summary
                      )}
                    />
                  );
                })}
              {userState && userState.currentList === "Φ" && (
                <Fragment>
                  <OnEditTextField
                    whiteField={true}
                    cueText="Search"
                    defaultValue={this.props.ui.searchTerm}
                    onTextChange={(text) => this.props.search(text)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#ddd" }} />
                      </InputAdornment>
                    }
                  />
                  {userState.AllLists.map((list) => {
                    return (
                      <TodoListContainer
                        key={list}
                        listId={list}
                        filterTask={(x) =>
                          x.extendedProperties.shared.done !== true &&
                          x.description
                            .toLowerCase()
                            .includes(
                              (this.props.ui.searchTerm || "").toLowerCase()
                            )
                        }
                        filterName={formatSummary(
                          userState[list].listMetadata.summary
                        )}
                      />
                    );
                  })}
                </Fragment>
              )}
              {userState && userState.currentList === "Ω" && (
                <TodoDateList lists={userState.AllLists} />
              )}
            </div>
          </div>
        </Paper>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapUIStateToProps = (state) => {
  return state || {};
};

const mapUIDispatchToProps = (dispatch) => {
  return {
    openListDrawer: () => {
      dispatch((dispatch, getState) => {
        const account = reducers.getUserState(getState());
        account &&
          dispatch(
            reducers.syncListsPendingAction(
              makeListObjectsFromCurrentAccount(account)
            )
          );
        dispatch(reducers.openDrawerAction());
      });
    },
    closeListDrawer: () => dispatch(reducers.closeDrawerAction()),
    reorderListOfLists: (indices) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        const email = state.auth.signedIn && state.auth.currentAccount;
        dispatch(
          reducers.reorderListsAction(email, indices.oldIndex, indices.newIndex)
        );
      });
    },
    createNewList: (text) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        const creator = state.auth.signedIn && state.auth.currentAccount;
        const listId = uuid();
        const anchorId = uuid();
        dispatch(
          reducers.createPendingListAction(
            listId,
            labelSummary(text),
            anchorId,
            creator
          )
        );
        dispatch(
          reducers.createPendingAnchorAction(anchorId, listId, Date.now())
        );
      });
    },

    hideEditListDialog: () => dispatch(reducers.hideEditListDialogAction()),
    hideDeleteListDialog: () => dispatch(reducers.hideDeleteListDialogAction()),
    deleteList: (id) => {
      dispatch(reducers.hideDeleteListDialogAction());
      dispatch(reducers.hideEditListDialogAction());
      dispatch((dispatch, getState) => {
        const state = getState();
        const list = reducers.getListState(state, id);
        const shared = list.shared;
        dispatch(reducers.deleteListPendingAction(id, shared));
      });
    },
    sync: () => sync(),
    syncAll: () => syncAll(),
    search: (searchTerm) => dispatch(reducers.setSearchTerm(searchTerm)),
  };
};

class EditListDialogUnstyled extends Component {
  render() {
    const { open, listId, listName, onCancel, classes } = this.props;
    const list = reducers.getListState(this.props, listId);
    const listCreator = list.creator || this.props.auth.currentAccount;

    return (
      <Dialog
        onClose={onCancel}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
        open={open}
      >
        <DialogTitle id="edit-dialog-title">Edit {listName}</DialogTitle>
        <DialogContent>
          <EnterText
            cueText="Share with email..."
            enterPressed={(email) =>
              this.props.shareList(listId, email, listCreator)
            }
          />
          {list.shared &&
            list.shared.map((email) => (
              <div
                key={email}
                className={classNames({
                  [classes.pendingShares]:
                    list.pendingShares && list.pendingShares[email],
                  [classes.pendingUnshares]:
                    list.pendingUnshares && list.pendingUnshares[email],
                })}
              >
                {email === listCreator && (
                  <IconButton edge="start" aria-label={email} disabled>
                    <AccountCircleIcon />
                  </IconButton>
                )}
                {email !== listCreator && (
                  <IconButton
                    edge="start"
                    aria-label={email}
                    onClick={() => this.props.unshareList(listId, email)}
                  >
                    <HilightOffIcon />
                  </IconButton>
                )}
                {email}{" "}
                {email === listCreator && (
                  <span className={classNames(classes.owner)}>owner</span>
                )}
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="start"
            aria-label="delete"
            onClick={this.props.showDeleteListDialog}
          >
            <DeleteIcon />
          </IconButton>
          <Button onClick={onCancel} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditListDialogUnstyled.propTypes = {
  listId: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapEditListDialogStateToProps = (state) => {
  return state || {};
};

const mapEditListDialogDispatchToProps = {
  shareList: reducers.createPendingShareAction,
  unshareList: reducers.createPendingUnshareAction,
  showDeleteListDialog: reducers.showDeleteListDialogAction,
};

const editListDialogStyles = {
  pendingShares: {
    opacity: "0.5",
  },
  pendingUnshares: {
    textDecoration: "line-through",
  },
  owner: {
    border: "1px solid grey",
  },
};

const EditListDialog = connect(
  mapEditListDialogStateToProps,
  mapEditListDialogDispatchToProps
)(withStyles(editListDialogStyles)(EditListDialogUnstyled));

class DeleteListDialog extends Component {
  render() {
    const { listNameToDelete, onDelete, onCancel, ...other } = this.props;

    return (
      <Dialog
        onClose={onCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        {...other}
      >
        <DialogTitle id="delete-dialog-title">
          Permanently delete list?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            This will delete the list &lsquo;{listNameToDelete}&rsquo;.
          </DialogContentText>
          <DialogContentText>
            Deleting a list also deletes all the tasks in it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteListDialog.propTypes = {
  listNameToDelete: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-class-assign
TodoApp = connect(mapUIStateToProps, mapUIDispatchToProps)(TodoApp);
// eslint-disable-next-line no-class-assign
TodoApp = withStyles(todoAppStyles)(TodoApp);
